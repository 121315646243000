<div class="title" style="display: absolute;">
    Opret liste
</div>
<form [formGroup]="createListForm" #form>
    <div class="ui-input-form">
        <div class="label">Navn</div>
        <input type="text"   formControlName="name" >
    </div>

    <div class="ui-input-form">
        <div class="label">Type</div>
        <select formControlName="type" placeholder="Vælg List type">
            <option disabled selected>Vælg List type</option>
            <option *ngFor="let listType of listTypes$ | async; let i = index;" [value]="listType.id" > {{listType.description}}</option>
        </select>
    </div>
      <div class="buttons">
          <button  class="ui-button success" (click)="submit()"  >
            Opret
        </button>
    </div>
</form>
