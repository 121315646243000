import {
  ChangeDetectorRef,
  Component, Input,
  OnInit,

} from '@angular/core';
import {PageItem} from '../../page.interface';
import {mergeDeep} from "../page-base/page-base.component";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ngx-sitemule-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent  implements OnInit {

  @Input() data;
  constructor(private http: HttpClient, private cdr: ChangeDetectorRef,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
    if(this.data.url){
      this.resolveUrl(this.data);
    }
  }


  // events
  public chartClicked(): void {
  }

  public chartHovered(): void {
  }

  resolveUrl(value) {
    this.http.get(value.url).subscribe((s:any) => {
      delete value.url;
      s.content.data.labels.forEach((label, index) =>{
        label = "web.chart.label." + label;
        s.content.data.labels[index] = this.translate.instant(label)
      })
      this.data = mergeDeep(value, s);
      this.cdr.detectChanges();
    });
  }

  /**
   * Simple object check.
   * @param item
   * @returns {boolean}
   */
  isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
  mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return mergeDeep(target, ...sources);
  }


}

export interface ChartPageItem extends PageItem {
  composition: {
    width: string;
    height: string;
  };
  content: {
    data?; //(SingleOrMultiDataSet) - set of points of the chart, it should be MultiDataSet only for line, bar, radar and doughnut, otherwise SingleDataSet
    datasets?; //({data: SingleDataSet, label: string}[]) - data see about, the label for the dataset which appears in the legend and tooltips
    labels?; //(Label[]) - x axis labels. It's necessary for charts: line, bar and radar. And just labels (on hover) for charts: polarArea, pie and doughnut. Label is either a single string, or it may be a string[] representing a multi-line label where each array element is on a new line.
    type?; //(ChartType) - indicates the type of charts, it can be: line, bar, radar, pie, polarArea, doughnut
    options?; //(ChartOptions) - chart options (as from Chart.js documentation)
    colors?; //(Color[]) - data colors, will use default and|or random colors if not specified (see below)
    legend?; //(boolean = false) - if true show legend below the chart, otherwise not be shown
  };
}
