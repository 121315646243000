import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UserService } from '../../services/sitemule-user.service';

@Component({
  selector: 'sitemule-user-mat-forgot-password',
  templateUrl: './mat-forgot-password.component.html',
  styleUrls: ['./mat-forgot-password.component.scss']
})
export class SitemuleUserMatForgotPasswordComponent implements OnInit {

  forgotPasswordForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
  }
  onSubmit() {
    this.userService.forgotPassword(this.forgotPasswordForm.value.username).pipe(
      first()
    ).subscribe((res) => {
      if (res) {
      } else {
      }
    });
  }
}
