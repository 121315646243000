<div class="chart-base-container">
  <div class="header" *ngIf="data.header">
    {{data.headerKey | translate : {default: data.header} }}
  </div>
  <div class="chart-container">
    <canvas baseChart *ngIf="data && data.content" style="max-width: 100%" [data]="data.content?.data"
      [options]="data.content.options" [type]="data.content.type" (chartHover)="chartHovered()"
      (chartClick)="chartClicked()">
    </canvas>
  </div>
</div>
