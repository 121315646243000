import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IListsDTO } from '../../../services/list.service';

@Component({
  selector: 'ngx-sitemule-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  @Input() listItem: IListsDTO;
  @Output() outExport = new EventEmitter<IListsDTO>();
  @Output() outDelete = new EventEmitter<IListsDTO>();
  @Output() outOpen = new EventEmitter<IListsDTO>();
  @Output() outPrint = new EventEmitter<IListsDTO>();
  @Output() outPrintInfo = new EventEmitter<IListsDTO>();
  @Output() outCopyListToBasket = new EventEmitter<IListsDTO>();

  delete() {
    this.outDelete.emit(this.listItem);
  }
  export() {
    this.outExport.emit(this.listItem);
  }
  open() {
    this.outOpen.emit(this.listItem);
  }
  print() {
    this.outPrint.emit(this.listItem);
  }
  printListInfo() {
    this.outPrintInfo.emit(this.listItem);
  }

  copyListToBasket() {
    this.outCopyListToBasket.emit(this.listItem);
  }
}
