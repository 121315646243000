import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CoreMenuService, IMenuItem, UserService } from '@sitemule/ngx-sitemule';
import { KeycloakService } from "keycloak-angular";
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
//
// const LogOutMenuItem: IMenuItem = {
//   label: 'Log ud',
//   link: 'user/account/logout',
// };

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() activeMenu;
  @Input() menuList;
  @Input() $userLoggedIn: Observable<any>;
  @Output() outMenuClicked = new EventEmitter<IMenuItem>();

  showDropdown = false;
  showDropdownMenu$ = this.menuService.getMenu('user_menu_mini').pipe(
    map((d: any[]) => [
      ...d.map((d) => {
        let newMenuitem: IMenuItem = {
          label: d.title,
          link: d.url,
        };
        return newMenuitem;
      }),
      // LogOutMenuItem,
    ])
  );
  isRedBarClosed = true;
  isBurgerOpen = false;
  $navigation = this.http.get<cmsMenu[]>('/cms/cms/listMenus');
  languages;
  currentLanguage;

  constructor(
    private route: Router,
    private http: HttpClient,
    private translateService: TranslateService,
    private cookie: CookieService,
    private userService: UserService,
    private menuService: CoreMenuService,
    private readonly keycloak: KeycloakService,
  ) {
  }

  selectLanguage(language) {
    const languages = this.translateService.getLangs();
    let path = window.location.pathname.toLowerCase();
    const hasLanguageInUrl = languages.some(languageA => path.split('/').includes(languageA));
    if (hasLanguageInUrl) {
      const split = path.split('/');
      split[1] = language;
      path = split.join('/');
    } else {
      path = '/' + language + path;
    }
    window.location.replace(path);
  }

  displayMenu = true;

  ngOnInit() {
    const url = this.route.url;
    this.displayMenu = !url.startsWith('/faq');
    this.languages = this.translateService.getLangs();
    this.currentLanguage = this.translateService.getDefaultLang();
  }


  goToFrontpage() {
    this.userService.isLoggedIn$.pipe(first()).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.route.navigate(['/user/dashboard']);
      }
      else {
        this.route.navigate(['']);
      }
    })
  }

  navigateTo(path) {
    if (path.link == '/cms/user/logoff' || path.link == 'user/account/logout') {
      this.keycloak.logout('https://my.logitrans.com/')
    }
    else {
      this.outMenuClicked.emit(path);
    }
  }

  loginDialog() {
    this.keycloak.login();
  }

  closeInfoBar() {
    this.cookie.set('specielInfo', 'Særlig information');
    this.isRedBarClosed = true;
  }

}

export interface cmsMenu {
  title: string;
  icon: string;
  show_icon: boolean;
  contentType: 'folder' | 'item' | 'link';
  seoUrl?: string;
  items: cmsMenu[];
}
