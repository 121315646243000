import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import 'hammerjs';
if (environment.production) {
  Sentry.init({
    dsn: "https://64acb067c8c14d508429b8fb47ebe72b@o263138.ingest.sentry.io/4504118423322624",
  });

  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
