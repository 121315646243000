import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BasketDialogService } from '../../../core/basket/basket-dialog.service';
import { BasketServiceV2 } from '../../../core/basket/basketv2.service';
import { IOrder, IOrderItem, OrdersService } from '../../services/orders.service';
import { CopyOrderToBasketComponent } from '../../user/dialogs/copy-order-to-basket/copy-order-to-basket.component';

@Component({
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersPageComponent implements OnDestroy {
  @ViewChildren('orderElements') orderElements: QueryList<ElementRef>;

  searchForm = new UntypedFormGroup({
    search: new UntypedFormControl(this.activatedRoute.snapshot.queryParams.search),
  });

  status$ = new BehaviorSubject('');
  search$ = new BehaviorSubject('');
  activeOrderId$ = new BehaviorSubject('');

  loadingOrders$ = new BehaviorSubject(false);
  orderGroups$ = combineLatest([
    this.status$,
    this.search$,
  ]).pipe(
    tap(() => {
      this.loadingOrders$.next(true);
    }),
    switchMap(([ status, search ]) => {
      return this.ordersService.getOrdersGroups(status, search);
    }),
    tap(() => {
      this.loadingOrders$.next(false);
    })
  );

  loadingOrderLines$ = new BehaviorSubject(false);
  activeOrderLines$ = this.activeOrderId$.pipe(
    tap(() => {
      this.loadingOrderLines$.next(true);
    }),
    switchMap((activeOrderId) => {
      if (!activeOrderId) {
        return of<IOrderItem[]>([]);
      }

      return this.ordersService.getOrderLines(activeOrderId);
    }),
    tap(() => {
      this.loadingOrderLines$.next(false);
    })
  );


  formSubscription: Subscription;
  paramSubscription: Subscription;
  constructor(
    private matDialog: MatDialog,
    private basketServiceV2: BasketServiceV2,
    private basketDialogService: BasketDialogService,
    public activatedRoute: ActivatedRoute,
    private ordersService: OrdersService,
    private router: Router
  ) {
    this.formSubscription = this.searchForm.get('search').valueChanges.subscribe(search => {
      this.router.navigate([],
        {
          queryParams: {
            search,
          },
          queryParamsHandling: 'merge'
        });
    });

    this.paramSubscription = this.activatedRoute.queryParams.subscribe(params => {
      const { search, status, activeOrderId } = params as { search: string, status: string, activeOrderId: string};

      this.searchForm.get('search').setValue(search, {
        emitEvent: false
      });

      if (this.status$.getValue() !== status) {
        this.status$.next(status);
      }
      if (this.search$.getValue() !== search) {
        this.search$.next(search);
      }
      if (this.activeOrderId$.getValue() !== activeOrderId) {
        this.activeOrderId$.next(activeOrderId);
      }
    });
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.paramSubscription?.unsubscribe();
  }

  setOrderActive(orderId: string) {
    this.router.navigate([],
      {
        queryParams: {
          activeOrderId: orderId
        },
        queryParamsHandling: 'merge'
      });
  }

  copyOrderToBasket(order) {
    this.matDialog
      .open(CopyOrderToBasketComponent)
      .afterClosed()
      .subscribe((shallCopy) => {
        if (!shallCopy) {
          return;
        }

        this.basketDialogService.openBasket();
        this.ordersService
          .copyOrderToBasket(order.id)
          .pipe(
            switchMap(() => {
              return this.basketServiceV2.loadBasket();
            })
          )
          .subscribe(() => {});
      });
  }
  mergeOrder(order: Omit<IOrder, 'items'>, lines: IOrderItem[]) : IOrder {
    return {
      ...order,
      items: lines
    }
  }
}
