<div class="grid">
  <div *ngIf="data.items" class="contact-grid">
    <ng-container *ngFor='let card of data.items'>
      <ng-container *ngIf="card.content">
        <h2 *ngIf="card.header">{{card.header}}</h2>
          <div class='contact-container' *ngFor="let item of card.content.items">
            <ui-business-card [card]="item"></ui-business-card>
          </div>
      </ng-container>
      <ng-container *ngIf="!card.content">
        <div class='contact-container'>
          <ui-business-card [card]="card"></ui-business-card>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
