import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { WebContainer } from '../../page.interface';
import { PageBaseComponent } from '../page-base/page-base.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-web-container',
  templateUrl: './web-container.component.html',
  styleUrls: ['./web-container.component.scss'],
})
export class WebContainerComponent extends PageBaseComponent {
  @Input() webContainerData: WebContainer;

  constructor(http: HttpClient, cdr: ChangeDetectorRef) {
    super(http, cdr);
  }
}
