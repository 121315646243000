import {Component, Input} from '@angular/core';
import {Article} from '../../page.interface';
import {Router} from '@angular/router';
import { navigateTo } from '../../../core/utility/routeNavigation';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent {

  constructor(private route:Router) {
  }

  @Input() articleOptions: Article;

  onBtnClick(action, evt: MouseEvent) {
    navigateTo(action.link.url, this.route, action.target);
    evt.stopPropagation();
    evt.preventDefault();
  }
}
