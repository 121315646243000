import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {catchError, first, map, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { BreadcrumbService, SiteConfigService, SitemulePageService } from '@sitemule/ngx-sitemule';
import Keycloak from "keycloak-js";
import {KeycloakService} from "keycloak-angular";

@Component({
  selector: 'app-page-stack',
  templateUrl: './page-stack.component.html',
  styleUrls: ['./page-stack.component.scss'],
})
export class PageStackComponent implements OnInit, AfterViewInit {
  @ViewChild('accordionComponent') accordionComponent: any;

  submitForm: object | undefined;
  pageContent$ = this.route.paramMap.pipe(
    switchMap((params: ParamMap) => {
      let urlGet = '';
      for (const key of params.keys) {
        if (key === 'seo') {
          const langList = ['da', 'en'];
          if (!langList.includes(params.get(key))) {
            urlGet = urlGet + params.get(key);
          }
        } else {
          urlGet = urlGet + '/' + params.get(key);
        }
      }
      if (urlGet === '') {
        urlGet = 'home';
      }
      return this.pageService.getContentBySeoUrl(urlGet).pipe(
        tap((res:any) => {
          switch (res.content.seourl) {
            case 'brands':
              res.content.items[1].contentType = 'stackAccordion';
              res.content.items[1].hasMenu = true;
              res.content.items[1].items.forEach((item) => {
                item.opened = false;
                if (!item.anchor) {
                  item.anchor = item.seourl;
                }
              });
              break;
            case 'service/reparation':
            case 'service/repair':
            case 'service/returnering':
            case 'service/returns':
            case 'service/fragtskader':
            case 'service/freightdamage':
              const insert = this.insertForm(res.content.seourl);
              res.content.items.splice(1, 0, insert);
              break;
            case 'om-os/medarbejdere':
            case 'about-us/employees':
              const medarbejdere = this.insertEmployees();
              res.content.items = [...res.content.items, medarbejdere];
              break;
          }
          if (res.content.title)
            this.titleService.setTitle(res.content.title ?? 'Folke Larsen A/S');
            this.metaService.updateTag(
              {name: 'description', content: res.content.metadescription ?? res.content.title ?? ''}
            );

	    this.metaService.updateTag(
              {name: 'keywords', content: res.content.keywords ?? res.content.metakeywords ?? ''}
	    )
          if (res.content.breadcrumb) {
            this.breadCrumbServices.setBreadcrumbList(res.content.breadcrumb);
          }
        }),
        catchError(this.handleError),
        tap((res:any) => {
          if (res.broken) {
            this.siteConfigService.$siteConfig.subscribe(res => {

              this.routes.navigateByUrl('/' + res.properties.meta_404_title);

            })
          }
        })
      );
    })
  );

  $activeUIMenu: Observable<any> = this.route.fragment.pipe(
    map((fragment) => {
      if (fragment) {
        return fragment;
      }
      return fragment;
    })
  );

  constructor(
    private titleService: Title,
    private metaService:Meta,
    private route: ActivatedRoute,
    private routes: Router,
    private pageService: SitemulePageService,
    private http: HttpClient,
    private breadCrumbServices: BreadcrumbService,
    private translate: TranslateService,
    private siteConfigService: SiteConfigService,
    private keycloak: KeycloakService
  ) {
  }

  login(){
    this.keycloak.login();
  }
  register(){
    this.routes.navigate(['/user/account/create']);
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 404) {
      console.warn('404!', error);
    } else {
      console.warn('PageStackComponent: Unknown status from server', error);
    }
    return of({broken: true});
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.route.fragment.pipe(first()).subscribe((fragment: string) => {
      if (fragment) {
        setTimeout((x) => {
          document
            .querySelector('#' + fragment)
            ?.scrollIntoView({behavior: 'smooth'});
        }, 200);
      }
    });
  }

  menuClick(event) {
    if (!event.disabled) {
      event.opened = true;
      this.setFragment(event);
    }
    // if (event.disabled === undefined) {
    //
    // }
  }

  openNextAccordion(event){
    if (!event.disabled) {
      event.opened = true;
    }
  }

  submitFormData(data) {
    if (!this.submitForm) {
      this.submitForm = data;
    } else {
      this.submitForm = Object.assign(this.submitForm, data);
    }
  }

  accordionToogle(event) {
    event.opened = !event.opened;
    if (location.hash.replace('#', '') !== event.anchor) {
      this.setFragment(event);
    } else {
      this.routes.navigate([location.pathname], {});
    }
  }

  private setFragment(event) {
    setTimeout((x) => {
      const fragment = location.hash.replace('#', '');
      if (event.anchor !== fragment) {
        if (event.anchor) {
          this.routes.navigate([location.pathname], {fragment: event.anchor});
          setTimeout(() => {
            // document.querySelector('#' + event.anchor).scrollIntoView({behavior: 'smooth'});
          }, 20);
        }
      } else {
        document
          .querySelector('#' + event.anchor)
          .scrollIntoView({behavior: 'smooth'});
      }
    }, 80);
  }

  // TODO: Rewrite entire form component and handling.
  stackAccClick(submit, formCheck) {
    const checkArr = [...formCheck];
    switch (submit.button.context.toLowerCase()) {
      case 'submit':
        // TODO: Rewrite entire form component and handling.
        if (submit.template?.items) {
          if (!checkArr.find(form => form.id === submit.template.items[0].id)) {
            checkArr.push(submit.template.items[0]);
          }

          Object.keys(this.submitForm).forEach(key => {
            submit.template.items[0].valid = key === submit.template.items[0].key;
          });
        }

        const isValid = checkArr.filter(form => {
          if (form.valid && !form.hidden) {
            return form;
          }
        });
        const notHiddenLength = checkArr.filter(form => form.hidden !== true).length;

        if (isValid.length === notHiddenLength) {
          let hiddenFields;
          checkArr.filter(form => {
            if (!form.hidden) {
              if (form.hiddenFields) {
                hiddenFields = form.hiddenFields;
              }
            }
            if (form.header === 'Kalibering' && !form.hidden) {
              const deleteKeys = ['00N0Y00000RI8qQ',
                '00N0Y00000RI8qI',
                '00N0Y00000RI8qH',
                '00N0Y00000RI8qF',
                '00N0Y00000S6SxS',
                '00N0Y00000RI8qL', 'number', 'formpicker1'];
              Object.keys(this.submitForm).forEach(key => {
                if (key === 'filedBy') {
                  Object.assign(this.submitForm, {name: this.submitForm[key]});
                  delete this.submitForm[key];
                }
                if (deleteKeys.includes(key)) {
                  delete this.submitForm[key];
                }
              });
            }
          });
          this.submitSalesForceForm(this.submitForm, hiddenFields);
        } else {
          checkArr.forEach(form => {
            if (!form.hidden && !form.disabled) {
              form.opened = true;
            }
          });
        }
        break;
      default:
        console.error('Unkown stack accordion context');
    }
  }

  private submitSalesForceForm(form, hiddenFields) {
    const dform = document.createElement('form');
    dform.setAttribute(
      'action',
      'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
    );
    dform.setAttribute('method', 'POST');
    let element;
    for (let i = 0; i < hiddenFields.length; i++) {
      element = document.createElement('input');
      element.setAttribute('type', 'hidden');
      element.setAttribute('name', hiddenFields[i].name);
      element.setAttribute('value', hiddenFields[i].value);
      dform.appendChild(element);
    }

    for (const prop in form) {
      const dfield = document.createElement('input');
      dfield.setAttribute('name', prop);
      dfield.setAttribute('value', form[prop]);
      dform.appendChild(dfield);
    }

    dform.style.display = 'none';
    document.body.appendChild(dform);

    dform.submit();
  }

  private insertForm(seourl) {
    let accForm;

    return accForm;
  }

  private insertEmployees() {
    const newThing = {
      // contentType: 'businessCards',
      contentType: 'stackAccordion',
      translationKey: 'call-us',
      header: 'Ring til os på tlf: 43 96 75 77',
      hasMenu: true,
      items: [],
    };
    this.http
      .get('/cms/cms/listContacts/1')
      .pipe(first())
      .subscribe((res: any) => {

        res = res.map((s) => {
          // TODO: Remove quickhack after services is changed
          s.anchor = s.header.toLowerCase();
          if (s.header !== 'Kundesupport') {
            s.opened = false;
          }
          s.contentType = 'businessCards';
          // quickhax ends.

          s.content.items = s.content.items.sort((a, b) => {
            return a.name - b.name;
          });
          return s;
        });
        newThing.items = res;
      });
    return newThing;
  }
}
