<ng-container *ngIf="page">
  <ng-container *ngIf="page.type">
    <ng-container *ngTemplateOutlet="switchCtx; context: { ctx: page.content }"></ng-container>
  </ng-container>
</ng-container>


<ng-template #switchCtx let-item="ctx" let-parentList="parentList">
  <ng-container [ngSwitch]="item.contentType">
    <ng-container *ngSwitchCase="'stack'">
      <ng-container *ngTemplateOutlet="stack; context: item">

      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'text'">
      <page-article [articleOptions]="item">

      </page-article>
    </ng-container>
    <ng-container *ngSwitchCase="'mediagallery'">
      <page-media-slider [mediaSlider]="item">
      </page-media-slider>
    </ng-container>
    <ng-container *ngSwitchCase="'webcontainer'">
      <page-web-container [webContainerData]="item">

      </page-web-container>
    </ng-container>
    <ng-container *ngSwitchCase="'table'">
      <ngx-sitemule-table-basic [data]="item"
      (rowClickOut)="tableRowClick($event)">

      </ngx-sitemule-table-basic>
    </ng-container>
    <ng-container *ngSwitchCase="'contactcards'">
      <ngx-sitemule-contact-cards [data]="item">

      </ngx-sitemule-contact-cards>
    </ng-container>
    <ng-container *ngSwitchCase="'chart'">
      <ngx-sitemule-chart [data]="item">

      </ngx-sitemule-chart>
    </ng-container>
    <ng-container *ngSwitchCase="'teaserstack'">
      <page-teaser-stack [teaserStack]="item">

      </page-teaser-stack>
    </ng-container>
    <ng-container *ngSwitchCase="'html'">
      <div [innerHTML]="item.html | safeSanitizer"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'businessCards'">
      <page-business-cards [bcData]="item">
      </page-business-cards>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <h3>COMPONENT-{{ item | json}}</h3>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #stack let-header="header" let-subHeader="subHeader" let-pageMenuId="pageMenuId" let-pageMenu="pageMenu"
             let-items="items">
  <ng-container>
    <h2 *ngIf="header && header.length > 0">{{ header }}</h2>
    <section *ngFor="let item of items; let i = index;" [class]="'page-' + item.contentType"
      [style]="'grid-area:' + item.seourl  + '' +  items.indexOf(item)">
      <ng-container *ngTemplateOutlet="switchCtx; context: { ctx: this.item }"></ng-container>
    </section>
  </ng-container>
</ng-template>
