import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';
import { SitemuleUserMatForgotPasswordComponent } from '../../../components/mat-forgot-password/mat-forgot-password.component';
import { MfaDialogComponent } from '../../../components/mfa-dialog/mfa-dialog.component';
import { LoginData } from '../../../models/User';
import { UserService } from '../../../services/sitemule-user.service';

@Component({
  selector: 'sitemule-user-mat-login',
  templateUrl: './mat-login.component.html',
  styleUrls: ['./mat-login.component.scss'],
})
export class SitemuleUserMatLoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  isDialog = false;

  togglePassword = false;
  mfaDialog: MatDialogRef<MfaDialogComponent>;
  data: LoginData;
  errorSubmit;
  resetPasswordMsg;
  @Input() loginBuildUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificationService: NotificationService,
    // public dialog: MatDialog,
    public dialogRef: MatDialogRef<SitemuleUserMatLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: LoginData,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.dialogData) {
      this.isDialog = true;
    }
    this.data = this.userService.newbuildLoginForm(this.loginBuildUrl);
    // Underneath is old way. before loginBuild from "server"
    // this.isDialog = Object.keys(this.dialogData);
    if (this.isDialog) {
      // this.data = this.dialogData;
      // If on mobile, make dialog expand to 100% width
      if (window.innerWidth < 768) {
        this.dialogRef.updateSize('100%');
      }
    } else {
      this.route.data.subscribe((res: any) => (this.data = res));
    }
    if (this.data.userInputType === 'email') {
      this.loginForm = this.formBuilder.group({
        username: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
      });
    } else {
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
      });
    }
  }

  onSubmit() {
    if (this.loginForm.valid) {
      let username: string = this.loginForm.value.username;
      username = username.trim();
      username = username.toLowerCase();
      this.userService.login(username, this.loginForm.value.password).subscribe(
        (res) => {
          if (res.user) {
            this.userService.updateCurrentSession(res);
            const navigateUrl = this.dialogData?.returnUrl
              ? this.dialogData?.returnUrl
              : '/user';
            this.router
              .navigate([res.user.language + '/' + navigateUrl])
              .then(() => {
                // window.location.reload();
              });
            this.dialogRef?.close(res.user);
          }
        },
        (err: any) => {
          this.errorSubmit = err.error?.message || err.statusText;
        }
      );
    } else {
      this.notificationService.show('Login Information Required', 'error');
    }
  }

  forgotPassword() {
    if (!this.loginForm.controls.username.value) {
      this.loginForm.markAllAsTouched();
      return;
    }
    this.userService
      .forgotPassword(this.loginForm.value.username)
      .pipe()
      .subscribe((res) => {
        if (res) {
          this.resetPasswordMsg =
            'Gendan password email sendt til:' + res.recipient;
        } else {
          this.errorSubmit = res;
        }
      });
  }

  openComponent(component?) {
    switch (component) {
      case 'forgotPassword':
        if (this.isDialog) {
          this.openDialog({}, SitemuleUserMatForgotPasswordComponent);
        } else {
          this.router.navigateByUrl('/' + component);
        }
        break;
      case 'registration':
        this.router.navigateByUrl('/user/account/create');
        this.dialogRef.close();
        break;
      default:
        if (component) {
          this.router.navigateByUrl('/' + component);
        } else {
          console.error('Unknown open Component');
        }
    }
  }

  private openDialog(data, component) {
    const dialog = this.dialog.open(component, {
      // height: '400px',
      // width: '400px',
      data,
    });
    dialog.afterClosed().subscribe(() => {
      // this.userService.loginMFA(result.deviceId, result.oneTimeKey).pipe(
      //   catchError((error: HttpErrorResponse) => {
      //     this.notificationService.show(error.message, 'error');
      //     return throwError(error);
      //   })
      // ).subscribe((mfaRes) => {
      //   this.handleSuccess(mfaRes);
      // });
    });
  }
}
