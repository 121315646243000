<p-table *ngIf="tableInitData" [columns]="tableInitData.columns"
         [value]="tableRowData"
         [rows]="tableInitData.options?.rows"
         [rowHover]="true"
         [scrollable]="tableInitData.options?.scrollable"
         [virtualScroll]="tableInitData.options?.virtualScrolling"
         [virtualRowHeight]="tableInitData.options?.virtualScrollingHeight ? tableInitData.options?.virtualScrollingHeight : 0"
         [lazy]="tableInitData.options?.lazy"
         [resizableColumns]="true"
         [autoLayout]="true"
         [lazyLoadOnInit]="tableInitData.options?.lazyLoadOnInit ? tableInitData.options?.lazyLoadOnInit : false"
         [scrollHeight]="tableInitData.options?.scrollHeight ? tableInitData.options?.scrollHeight : ''"
         (onLazyLoad)="lazyLoadEmit($event)"
         [responsiveLayout]="tableInitData.options?.responsiveLayout ? tableInitData.options?.responsiveLayout : 'scroll'"
>
  <ng-template class="p-table-caption" pTemplate="caption" *ngIf="tableInitData.caption">
    <div class="caption-div">
      {{tableInitData.caption}}
    </div>
    <ng-container *ngIf="!tableInitData.options?.globalFilter == undefined">
      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="pTable.filterGlobal($event.target.value, 'contains')"
               placeholder="Search keyword"/>
      </span>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <ng-container *ngFor="let col of columns">
        <ng-container *ngIf="!tableInitData.options?.sort; else colSort">
          <th [style]="col.style" pResizableColumn>
            {{col.header | lowercase | translate }}
          </th>
        </ng-container>
        <ng-template #colSort>
          <th [style]="col.style" [pSortableColumn]="col.field">
            {{col.header| lowercase | translate }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </ng-template>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr style="cursor: pointer" (click)="rowClick(rowData)" [style.height]="tableInitData.options?.virtualScrollingHeight +'px'">
      <td [style]="col.style" *ngFor="let col of columns">
        <ng-container [ngSwitch]="getSwitchCase(rowData,col)">

          <ng-container *ngSwitchCase="'dropdown'">
            <p-dropdown (click)="preventClick($event)" appendTo="body" [id]="id" [options]="rowData[col.field].options"
                        [(ngModel)]="rowData[col.field].selected" [optionLabel]="'label'" [optionValue]="'value'">
            </p-dropdown>
          </ng-container>
          <ng-container *ngSwitchCase="'link'">
            <a (click)="linkClick(rowData[col.field].link, $event)" href="{{rowData[col.field].link}}" target="_blank">Link</a>
          </ng-container>

          <ng-container *ngSwitchCase="'date'">
            {{rowData[col.field] | formatDate2}}
          </ng-container>


          <ng-container *ngSwitchDefault>
            {{rowData[col.field]}}
          </ng-container>

        </ng-container>
      </td>
    </tr>

  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="flex align-items-center justify-content-between">
      {{tableInitData.summary}}
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">{{'web.table.noRecords' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
