import {Component, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {CreateAccountPageComponent} from './pages/create-account/create-account-page.component';
import {FirstPasswordPageComponent} from './dialogs/first-password/first-password-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../modules/material.module';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {FirstPasswordDialogGuard} from './dialogs/first-password/first-password-dialog.guard';
import {LoginDialogGuard} from './dialogs/mat-login/login-dialog.guard';
import {AddressComponent} from './dialogs/address/address.component';
import {LogoutDialogGuard} from './dialogs/mat-login/logout-dialog.guard';
import {OrderConfirmationComponent} from './dialogs/order-confirmation/order-confirmation.component';
import {ReferenceComponent} from './dialogs/reference/reference.component';
import { CopyOrderToBasketComponent } from './dialogs/copy-order-to-basket/copy-order-to-basket.component';
import { SitemuleUiModule } from '../../ui/sitemule-ui.module';

@Component({
  template: ``,
})
export class EmptyComponent {
}

const routes: Routes = [
  {
    path: 'create',
    component: CreateAccountPageComponent
  },
  {
    path: 'login',
    resolve: {
      doesntMatter: LoginDialogGuard,
    },
    component: EmptyComponent,
  },
  {
    path: 'logout',
    resolve: {
      doesntMatter: LogoutDialogGuard,
    },
    component: EmptyComponent,
  },
  {
    path: 'password/:ticket',
    resolve: {
      doesntMatter: FirstPasswordDialogGuard,
    },
    component: EmptyComponent,
  }];

@NgModule({
  declarations: [FirstPasswordPageComponent, AddressComponent, OrderConfirmationComponent, ReferenceComponent, CopyOrderToBasketComponent],
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes), FormsModule, ReactiveFormsModule, TranslateModule.forChild(), MaterialModule, MatDialogModule, SitemuleUiModule],
  exports: [],

  providers: [TranslatePipe]
})
export class AccountModule {
}



