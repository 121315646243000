<div class="profile-section">
  <div class="profile-section__title" *ngIf="title">{{title | translate}}</div>
  <div class="profile-section__items" *ngIf="items?.length">
    <div class="profile-section__item-group" *ngFor="let innerItems of items">
      <ng-container *ngFor="let item of innerItems">
        <div class="profile-section__item__label" [class.multiline]="item.preserveMultiline">{{item.label | translate}}</div>
        <div class="profile-section__item__value" [class.multiline]="item.preserveMultiline">{{item.value}}</div>
      </ng-container>
    </div>
  </div>
  <div class="profile-section__content">
    <ng-content></ng-content>
  </div>
</div>
