import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface IProfilePageSectionItem {
  label: string;
  value: string;
  preserveMultiline?: boolean;
}

@Component({
  selector: 'sm-profile-page-section',
  templateUrl: './profile-page-section.component.html',
  styleUrls: ['./profile-page-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePageSectionComponent {
  @Input() title = '';
  @Input() items : IProfilePageSectionItem[][] = [];
}
