<div class="title" style="display: absolute;">
  {{'web.list.addto.title' | translate }}
</div>
<form [formGroup]="selectListForm" #form tabindex="-1">

  <div class="ui-input-combined" style="    margin-top: 20px;
    margin-bottom: 20px;">

    <input type="text" placeholder="{{'web.list.addto.searchfield' | translate }}" class="ui-input search"
           formControlName="name" [matAutocomplete]="auto" [autofocus]="false">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setList($event.option.value)">
      <mat-optgroup *ngFor="let list of filteredLists$ | async" [label]="list.label">
        <mat-option *ngFor="let item of list.items" [value]="item">
          {{item.description}}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </div>
</form>

<div>
  <button class="ui-button" (click)="createNewList()">{{'web.createnew' | translate}}</button>
  <button style="float: right" class="ui-button success" (click)="selectList()">{{'web.add' | translate}}</button>
</div>
