import { Component, Input } from '@angular/core';
import { MenuService } from '../../../core/services/menu.service';
import { SiteConfigServiceV2 } from '../../../core/services/site-config.service';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  @Input() activeMenuTextColor: string = '';
  @Input() expandedMenuItemsBorder: string = '';
  constructor(private menuService: MenuService, private siteSettings: SiteConfigServiceV2) {}

  navigationItems$ = this.menuService.getMenuBySystemPropertyName('user_menu_root');
  activeUrl$ = this.siteSettings.$activeUrl;
}
