import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '@sitemule/ngx-sitemule';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-header-and-footer',
  templateUrl: './header-and-footer.component.html',
  styleUrls: ['./header-and-footer.component.scss'],
})
export class HeaderAndFooterComponent implements OnInit {
  $activeMenu: Observable<any> = this.route.events.pipe(map(route => {
    if (route instanceof NavigationEnd) {
      return this.getActiveMenu(route.url.toLowerCase().split('/')[2]);
    }
  }));
  contextMenuList = [];
  logoMenuList = [];
  hide = false;
  isFaq = false;
  $user = this.userService.currentSession$;

  $showScrollButton = fromEvent(window, 'scroll').pipe(debounceTime(50), map(s => {
    return document.documentElement.scrollTop > 600;
  }));

  constructor(private route: Router,
              private userService: UserService) {
    route.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.hide = e.url.toLowerCase().includes('/user') || e.url.toLowerCase().includes('/user');
      }
    });
  }

  ngOnInit(): void {
    this.initMenu();

    //TODO: RRemove quickfix about show faq in PROD
    this.isFaq = this.route.url.startsWith('/faq');
  }


  private getActiveMenu(url: string) {
    const mergedArr = [...this.contextMenuList, ...this.logoMenuList];
    return mergedArr.find(menuItem => url?.startsWith(menuItem.seourl));
  }

  menuClicked(item) {
    const url = item.link ? item.link : item;
    this.route.navigate(['/' + url]);
  }

  breadcrumbClick(breadcrumb) {
    this.route.navigate([`/${breadcrumb.seourl}`]);
  }

  searchProduct(searchTxt) {
    searchTxt = searchTxt.trim();
    this.route.navigate(['/products'], {queryParams: {search: searchTxt}});
  }

  scrollTop() {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }

  private initMenu() {
    this.logoMenuList = [
      {
        label: 'faq',
        link: 'faq',
        active: false,
      } ];
  }

}
