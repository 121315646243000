import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  Provider,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { RadioField } from '../../fields.model';
const RADIOGROUP_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioGroupComponent),
  multi: true,
};
@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RADIOGROUP_CONTROL_VALUE_ACCESSOR],
})
export class RadioGroupComponent {
  @Input() field!: RadioField;

  value: any;
  public disabled = false;

  private onChange?(value): void;
  // @ts-ignore
  private onTouched?(): void;

  constructor(private cdf: ChangeDetectorRef) {}

  writeValue(obj: any): void {
    this.value = obj;
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdf.markForCheck();
  }
}
