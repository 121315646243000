<div class="user-profile-container" *ngIf="currentSession$ | async as currentSession">
  <sm-profile-page-section
    *ngFor="let section of sections$ | async"
    [title]="section.title"
    [items]="section.items"
  ></sm-profile-page-section>
  <sm-profile-page-section title="web.user.login">
    <div class="user-profile-password-section">
      <div class="left">
        <div class="label">{{'web.user.username' | translate}}</div>
        <span>{{currentSession.user?.profile_id}}</span>
      </div>
      <form class="right" [formGroup]="changePassword" (ngSubmit)="submit()">
        <div class="ui-input-form">
          <div class="label">{{'web.user.currentpassword' | translate}}</div>
          <input type="text" required formControlName="currentPassword">
        </div>
        <div class="ui-input-form">
          <div class="label">{{'web.user.newpassword' | translate}}</div>
          <input type="text" required formControlName="newPassword">
        </div>
        <div class="ui-input-form">
          <div class="label">{{'web.user.repeatpassword' | translate}}</div>
          <input type="text" required formControlName="repeatPassword">
        </div>
        <button class="ui-button" style="font-size: 14px;" type="submit">
          {{'web.user.updatepassword' | translate}}
        </button>
        <span *ngIf="passwordHasBeenChanged">{{'web.user.passwordchanged' | translate }}</span>
        <span *ngIf="passwordError">{{changePasswordMessage}}</span>
      </form>
    </div>
  </sm-profile-page-section>
</div>
