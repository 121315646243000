import {Component, Inject} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ListService} from '../../../services/list.service';

@Component({
  templateUrl: './create-list.component.html',
  styleUrls: ['./create-list.component.scss']
})
export class CreateListComponent {
  selectedCategory;
  createListForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl('', Validators.required),
  });
  listTypes$ = this.listService.getListTypes();

  constructor(public listService: ListService,
              private dialogRef: MatDialogRef<CreateListComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData) {
    this.selectedCategory = dialogData.selectedCategory;
  }

  submit() {
    if (this.createListForm.invalid) {
      this.createListForm.markAllAsTouched();
      return;
    }
    const data = {
      'description': this.createListForm.value.name,
      collection_id: this.createListForm.value.type,
    };
    this.listService.createOrUpdateList('0', data).subscribe(res => {
      this.dialogRef.close(res);
    });
  }

}
