import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SiteConfigService, SitemuleUserSession, UserService} from '@sitemule/ngx-sitemule';
import {CookieService} from 'ngx-cookie-service';
import {first} from 'rxjs/operators';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private cookie: CookieService,
              private translate: TranslateService,
              private http: HttpClient,
              private sitesettingService: SiteConfigService,
              private userService: UserService,
              private router: Router
  ) {

  }

  systemDefault = '';

  loadUser(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.userServiceLoad().then(() => {
        this.userService.getCurrentSession().subscribe(res => {
          //FIXME: this is a quickfix for setting language on login based on user language, and then use url afterwards.
          if (res?.user?.language) {
            if (this.translate.getLangs().includes(res.user.language)) {
              if (!window.location.href.includes('user') && !window.location.href.includes('faq') && !window.location.href.includes('missinginfo')) {
                this.router.navigate(['/' + res.user.language + '/user/dashboard']);
                this.setLanguage('/' + res.user.language);
              }
            }
          } else {
            if (!window.location.href.includes('user') && !window.location.href.includes('faq') && !window.location.href.includes('missinginfo')) {
              this.router.navigate(['/user/dashboard']);
            }
          }
          if (!res?.customer) {
            this.http.post('/cms/user/logoff', {}).subscribe((res: SitemuleUserSession) => {
              this.userService.updateCurrentSession(res);
              reject();
            })
          } else {
            resolve()
          }
        }, error => {
          reject();
        })
      })
    })
  }

  load(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.sitesettingService.$siteConfig.pipe(first()).subscribe(res => {
          this.translate.addLangs(res.system.data_languages);
          if (res.system.default_language) {
            this.systemDefault = res.system.default_language;
            this.setLanguage(location.pathname);
          }
          resolve();
        },
      );
    });
  }

  userServiceLoad() {
    return new Promise<void>((resolve) => {
      this.http.get('/cms/usr/isClientLogin').subscribe(
        (res: any) => {
          this.userService.load().then(resolve);
        },
        () => {
          resolve();
        }
      );
    });
  }


  downloadInitialData() {
    return Promise.all([
      this.load(),
      this.userServiceLoad()
    ]);
  }

  public setLanguage(lang: string) {
    const getLang = lang.split('/');
    const langList = this.translate.getLangs();
    const cookieLang = this.cookie.get('lang');
    const browserLang = this.translate.getBrowserLang();
    const userLang = this.userService?.currentuser?.language || "";

    switch (true) {
      // Url lang
      case langList.includes(getLang[1]):
        this.http.post('/cms/cms/setLanguage/' + getLang[1], {}).subscribe();
        this.translate.setDefaultLang(getLang[1]);
        this.cookie.delete('lang');
        this.cookie.set('lang', getLang[1], 0, '/', '', false);
        break;
      // User lang
      case userLang != '':
        this.http.post('/cms/cms/setLanguage/' + userLang, {}).subscribe();
        this.translate.setDefaultLang(userLang);
        this.cookie.delete('lang');
        this.cookie.set('lang', userLang, 0, '/', '', false);
        break;
      // Cookie lang
      case cookieLang !== '':
        this.http.post('/cms/cms/setLanguage/' + cookieLang, {}).subscribe();
        this.translate.setDefaultLang(cookieLang);
        break;
      // Portfolio system default
      case this.systemDefault != "":
        this.http.post('/cms/cms/setLanguage/' + this.systemDefault, {}).subscribe();
        this.translate.setDefaultLang(this.systemDefault);
        break;
      // Browser lang
      case langList.includes(browserLang):
        this.http.post('/cms/cms/setLanguage/' + browserLang, {}).subscribe();
        this.translate.setDefaultLang(browserLang);
        break;
      // Default to Dk.
      default:
        this.translate.setDefaultLang('da');
        this.cookie.set('lang', 'da');
        break;
    }
  }

}
