import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Status } from '../../core/basket/basket.interfaces';
import { IAddress } from '../../core/basket/basketv2.service';


const orderResponseToOrder = (order: IOrderResponse): Omit<IOrder, 'items'> => {
  return {
    id: order.order_id,
    status: order.order_status,
    orderDate: order.order_date,
    isDeliveryForFree: order.order_total_free_delivery,
    missingAmountForFreeDelivery:
      order.order_total_additional_buy_for_free_delivery,
    discountAmount: order.total_discount_amount,
    totalAmountIncludingDiscount: order.total_net_amount,
    deliveryAmount: order.total_freight_amount,
    vatAmount: order.total_vat,
    totalAmount: order.total_total_order,
    deliveryDate: order.delivery_date,
    reference: order.reference,
    deliveryRemark: order.delivery_remark,
    customerName: order.customer.name,
    delivery: {
      name: order.delivery_address_name,
      address1: order.delivery_address_1,
      address2: order.delivery_address_2,
      city: order.delivery_city,
      zipcode: order.delivery_zipcode,
      country: order.delivery_country,
      phoneNumber: order.delivery_phone,
    },
    billing: {
      name: order.billing_address_name,
      address1: order.billing_address_1,
      address2: order.billing_address_2,
      city: order.billing_city,
      zipcode: order.billing_zipcode,
      country: order.billing_country,
      phoneNumber: order.billing_phone,
    },
    agreement: {
      acceptSalesAndDeliveryTerms:
        order.properties?.acceptSalesAndDeliveryTerms,
      collectAtCompany: order.properties?.collectAtCompany,
      useCreditCard: order.properties?.useCreditCard,
    },
  };
};
@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private orderLinesCache : {[key : string] : IOrderItem[]} = {};

  constructor(private http: HttpClient) {}

  // TODO: Send search and statusCode as HttpParams so service can do the filtering
  // * Service: Always return same model, when called with ?status_code=40 it returns array of orders, instead of orderGroups
  // * Service: Search on 40 returns 404, ?status_code=40&search=abc returns 404
  // * Service: Search is not accurate, ?status_code=&search=<orderNumber> returns nothing
  // * Frontend: Send statusCode and search as HttpParams, and remove two map() that is doing the filtering in frontend.
  getOrdersGroups(
    statusCode?: string,
    search?: string
  ): Observable<IOrderGroup[]> {
    return this.http
      .get<IOrderGroupResponse[]>(
        '/cms/ord/ordersByCustomer/0',
        {
          params: new HttpParams()
            .set('page', '')
            .set('search', '')
            .set('limit', '')
            .set('status_code', ''),
        }
      )
      .pipe(
        // Filter out groups without items
        map((ordersGroups) => {
          return ordersGroups
            .filter(ordersGroup => ordersGroup.items?.length);
        }),
        // Filter out by statusCode
        map((ordersGroups) => {
          if (!statusCode) {
            return ordersGroups;
          }

          return ordersGroups
            .filter(ordersGroup => {
              return statusCode === `${ordersGroup.status_code}`
            });
        }),
        // Filter out by search
        map((ordersGroups) => {
          if (!search) {
            return ordersGroups;
          }
          return ordersGroups
            .map(ordersGroup => {
                const orders = ordersGroup.items;
                const matchedOrders = orders.filter(order => {
                  return JSON.stringify(order).toLowerCase().includes(search.toLowerCase());
                });
                if (matchedOrders.length) {
                  return {
                    ...ordersGroup,
                    items: matchedOrders
                  }
                }

                return null;
            })
            .filter(ordersGroup => ordersGroup);
        }),
        // Map to internal model
        map((ordersGroups) => {
          return ordersGroups.map<IOrderGroup>((ordersGroup) => {
              return {
                description: ordersGroup.description,
                items: ordersGroup.items?.map(orderResponseToOrder) || [],
              };
            });
        }),
      );
  }

  getOrderLines(orderId: string): Observable<IOrderItem[]> {
    // Orderlines of an order should never change
    if (this.orderLinesCache[orderId]) {
      return of(this.orderLinesCache[orderId]);
    }

    return this.http
      .get<IOrderItemResponse[]>('/cms/ord/order/' + orderId + '/lines')
      .pipe(
        map((lines) => {
          return lines.map((line) => {
            return {
              id: `${line.order_line_id}`,
              image: line.product.image,
              title: line.product.title,
              productId: `${line.product.product_id}`,
              productNumber: line.product.productNumber,
              productSEOUrl: line.product.seourl,
              productSEOUrlId_DONOT_USE_THIS: line.product.seourlid,
              quantity: line.quantity,
              pricePerUnit: line.price,
              price: line.price * line.quantity,
            };
          });
        }),
        tap((lines) => {
          this.orderLinesCache[orderId] = lines;
        })
      );
  }

  copyOrderToBasket(orderId: string): Observable<void> {
    return this.http.get<void>('cms/ord/copyOrderToBasket/' + orderId);
  }
}

export interface IOrderGroup {
  description: string;
  items: Omit<IOrder, 'items'>[];
}
export interface IOrderItem {
  id: string;
  image: string;
  title: string;
  productId: string;
  productNumber: string;
  productSEOUrl: string;
  productSEOUrlId_DONOT_USE_THIS: string;
  quantity: number;
  pricePerUnit: number;
  price: number;
  inventoryStatusText?: string;
  inventoryStatus?: 'IN_STOCK' | 'ON_THE_WAY' | 'SOLD_OUT';
}

export interface IOrder {
  id: string;
  status: Status;
  orderDate: string;
  isDeliveryForFree: boolean;
  missingAmountForFreeDelivery: number;
  discountAmount: number;
  totalAmountIncludingDiscount: number;
  deliveryAmount: number;
  vatAmount: number;
  totalAmount: number;
  deliveryDate?: string;
  reference?: string;
  deliveryRemark?: string;
  customerName?: string;
  delivery?: IAddress;
  billing?: IAddress;
  agreement?: {
    acceptSalesAndDeliveryTerms: boolean;
    useCreditCard: boolean;
    collectAtCompany: boolean;
  };
  items: IOrderItem[];
}

interface IOrderGroupResponse {
  description: string;
  status_code: number;
  items?: IOrderResponse[];
}

interface IOrderResponse {
  order_id: string;
  order_status: Status;
  order_date: string;
  order_total_free_delivery: boolean;
  order_total_additional_buy_for_free_delivery: number;
  total_vat: number;
  delivery_remark: string;
  reference: string;
  delivery_date: string;
  billing_address_name: string;
  billing_address_1: string;
  billing_address_2: string;
  billing_zipcode: string;
  billing_city: string;
  billing_country: string;
  billing_phone: string;
  delivery_address_name: string;
  delivery_address_1: string;
  delivery_address_2: string;
  delivery_zipcode: string;
  delivery_city: string;
  delivery_country: string;
  delivery_phone: string;
  customer: {
    name: string;
    reference: string;
  };
  properties?: {
    acceptSalesAndDeliveryTerms: boolean;
    useCreditCard: boolean;
    collectAtCompany: boolean;
  };
  order_total_price: number;
  total_discount_amount: number;
  total_net_amount: number;
  total_freight_amount: number;
  total_total_order: number;
}

interface IOrderItemResponse {
  order_line_id: number;
  product: {
    image: string;
    product_id: number;
    productNumber: string;
    seourl: string;
    seourlid: string;
    title: string;
  };
  quantity: number;
  price: number;
}
