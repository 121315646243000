import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, startWith, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../../../core/services/notification.service';
import { ListService, SingleList } from '../../../services/list.service';
import { CreateListComponent } from '../../dialog/create-list/create-list.component';
import { EditListComponent } from '../../dialog/edit-list/edit-list.component';

@Component({
  templateUrl: './list-overview-page.component.html',
  styleUrls: ['./list-overview-page.component.scss'],
})
export class ListOverviewPageComponent {
  constructor(
    private matDialog: MatDialog,
    private listService: ListService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
  ) {}

  listType;
  data$ = this.initiateSearchForm();
  orgData$;
  sortDir = '';

  searchForm = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  });
  initiateSearchForm() {
    return this.activatedRoute.params.pipe(
      switchMap((s) => {
        this.listType = s.listType;
        return this.searchForm.valueChanges.pipe(
          startWith({}),
          debounceTime(100),
          switchMap((searchForm) => {
            return this.listService.getListsBasedOnListTypes(
              searchForm.search,
              this.listType
            );
          })
        );
      })
    );
  }

  createList() {
    const dialogRef = this.matDialog.open(CreateListComponent, {
      maxWidth: '100%',
      panelClass: 'small-dialog',
      data: {},
    });
    dialogRef.afterClosed().subscribe((s: SingleList) => {
      if (s) {
        this.openList(s);
      }
    });
  }

  exportList(list: SingleList) {
    window.open(
      `${location.protocol}//${location.host}/cms/cmn/createexcel?payload={"entity":"list","reportType" : "list","id" : ${list.list_id}}`
    );
  }

  deleteList(list: SingleList) {
    this.listService.deleteList(list).subscribe(() => {
      this.data$ = this.initiateSearchForm();
    });
  }
  sortList(sortBy) {
    this.setSortDir(this.sortDir);
    if (!this.orgData$) {
      this.orgData$ = this.data$;
    }
    if (!sortBy || this.sortDir == '') {
      this.data$ = this.orgData$;
    }
    this.data$ = this.data$.pipe(
      tap((results: any) => {
        results.sort((a, b) => {
          let propertyA: number | string = '';
          let propertyB: number | string = '';

          switch (sortBy) {
            case 'overskrift':
              [propertyA, propertyB] = [a.description, b.description];
              break;
            case 'dato':
              [propertyA, propertyB] = [a.properties.date, b.properties.date];
              break;
          }

          const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
          const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
          return (valueA < valueB ? -1 : 1) * (this.sortDir == 'asc' ? 1 : -1);
        });
      })
    );
  }

  setSortDir(dir) {
    switch (dir) {
      case '':
        this.sortDir = 'asc';
        break;
      case 'asc':
        this.sortDir = 'desc';
        break;
      case 'desc':
        this.sortDir = '';
        break;
    }
  }
  printList(list: SingleList) {
    this.listService.printList(list.list_id);
  }
  printQRList(list: SingleList) {
    this.listService.printListAsInfoCards(list.list_id);
  }
  openList(list: SingleList) {
    const dialogRef = this.matDialog.open(EditListComponent, {
      panelClass: 'ui-dialog',
      position: { right: '0', top: '0', bottom: '0' },
      maxHeight: 'none',
      maxWidth: 'none',
      data: {
        listId: list.list_id,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.data$ = this.initiateSearchForm();
    });
  }

  copyListToBasket(list: SingleList) {
    this.listService.copyListToBasket(`${list.list_id}`)
      .subscribe(() => {
        this.notificationService.show('Added to basket', 'success');
      });
  }
}
