<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<div mat-dialog-content>
  <p *ngIf="data.content" style="font-size: 16px;
    margin: auto;
    padding-bottom: 20px;">{{data.content}}</p>
  <mat-form-field style="width: 100%;">
    <mat-label>{{data.placeholder}}</mat-label>
    <input matInput [(ngModel)]="data.input" (keyup.enter)="onYesClick(data.input)" cdkFocusInitial>
  </mat-form-field>
</div>
<div mat-dialog-actions style="justify-content: space-between; padding-bottom: 20px;">
  <button class="ui-button" style="width: 25%;" (click)="onNoClick()">
    Tilbage
  </button>
  <button class="ui-button" style="width: 25%; color: white; background-color: rgb(0, 59, 110);"
          (click)="onYesClick(data.input) ">
    Send
  </button>
</div>
