<div class="basket-container">
  <ng-container *ngIf="currentBasket$ | async as basket">
    <div class="top">
      <div class="header">
        <h2>{{basket.items.length}} {{ 'web.order.itemsinbasket' | translate }}</h2>
        <i class="icon-arrowright" (click)="closeDialog()"></i>
      </div>

      <div class="message">
        <span>
          {{'web.order.orderbeforeandgettomorrow' | translate}}
        </span>
      </div>

    </div>
    <div class="content">
      <!-- TODO -->
      <user-pricelist [mobileVersion]="true" [productNumberToFocus]="data.productNumberToFocus" style="flex: 1;"
        *ngIf="basket.items.length" [basket]="basket" (outUpdate)="updateOrderLine($event)"
        (outRemove)="removeFromBasket($event)" [readonly]="false" [hidePrice]="true">
      </user-pricelist>
    </div>
    <div class="bottom">
      <div class="offer"
        *ngIf="!basket.isDeliveryForFree && basket.missingAmountForFreeDelivery">
        <b>{{'web.order.orderforadditionalandgetfreedelivery' | translate}}
          {{basket.missingAmountForFreeDelivery| sitemuleCurrency: 'financial' }} </b>
      </div>


      <div class="price">
        <div class="line" *ngIf="basket.discountAmount != 0">
          <p>{{'web.order.discount' | translate}}</p>
          <p>{{basket.discountAmount | sitemuleCurrency: 'financial' }} </p>
        </div>
        <div class="line">
          <p>{{'web.order.totalprice' | translate}}</p>
          <p>{{basket.totalAmountIncludingDiscount || 0 | sitemuleCurrency: 'financial'}}</p>
        </div>
        <div class="spacing-line"></div>
        <div class="line">
          <p>{{ 'web.order.delivery' | translate }}</p>
          <p>{{basket.deliveryAmount | sitemuleCurrency: 'financial' }} </p>
        </div>
        <div class="line">
          <p>{{ 'web.order.vat' | translate }}</p>
          <p>{{basket.vatAmount | sitemuleCurrency: 'financial' }} </p>
        </div>
        <div class="line">
          <p><b>{{ 'web.order.totalpriceinklvat' | translate }}</b></p>
          <p class="totalpricebasket">{{basket.totalAmount | sitemuleCurrency: 'financial'}}</p>
        </div>
        <button class="ui-button success" (click)="goToBasket()" [disabled]="currentBasketLoading$ | async">
          {{ 'web.order.gotobasket' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ngx-loading [show]="true" *ngIf="currentBasketLoading$ | async">
  </ngx-loading>
</div>
