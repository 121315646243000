<div *ngIf="mediaSlider" class="media-slider-wrapper project-content-margin" [class.full-width]="mediaSlider.composition?.fullPageWidth"
     #wrapper [style.height.px]="mediaSlider.composition.height" (pan)="onPan($event)"
     (mouseover)="stopInterval()" (mouseout)="startInterval()">
  <div class="slides-container" [style.width.%]="mediaSlider.items.length * 100" [class.animating]="isAnimating"
       [style.transform]="'translateX( ' + percentage + '% )'">
    <div *ngFor="let slide of mediaSlider.items" class="slide" (click)="onSlideItemClick(slide, $event)">
      <div [smScaleImage]="slide.url" [smWidthRounding]="100" [class]="
          'slide-background' +
          ' va-' +
          slide.layout?.textAlignment?.vertical ? slide.composition?.textAlignment?.vertical : 'bottom' +
          ' ha-' +
          slide.layout?.textAlignment?.horizontal ? slide.composition?.textAlignment?.horizontal : 'right'
        " [style.display]="'flex'" [style.flex-direction]="'column'" [style.height.px]="mediaSlider.composition.height"
           [style.color]="slide.layout?.textColor"
           [style.background-size]="'cover'"
           [style.background-position]="slide.layout && slide.layout?.backgroundPosition && slide.layout?.backgroundPosition.length > 0 ? slide.layout?.backgroundPosition : 'top'">


        <!-- TODO: #4 add correct check for left or right when services give us the correct information -->
        <div class="project-content-margin caption-container"
             [style.justify-content]="slide.layout?.imageposition == 'lc' ? 'flex-end' : ''">
          <div
            [style.backgroundColor]="slide?.layout?.textBackgroundColor"
            class="caption">
            <h2 [style.color]="slide.layout?.textColor" [innerHTML]="slide.title"></h2>
            <span [style.color]="slide.layout?.textColor" [innerHTML]="slide.caption | marked"></span>
            <ng-container *ngIf="slide.buttonText">
              <button class="ui-button"
                      [ngStyle]="{
              'background-color':slide.layout?.buttonBackgroundColor,
              'color':slide.layout?.buttonTextColor,
              'cursor':'pointer',
              'margin-top': '10px'}">
                {{slide.buttonText}}
              </button>
            </ng-container>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="mediaSlider.items.length > 1">
      <div class="navigation-button left ui-icon-slideleft" (click)="previous()"></div>
      <div class="navigation-button right ui-icon-slideright" (click)="next()"></div>
    </div>
    <div class="bullets-container" *ngIf="mediaSlider.items.length > 1">
      <div *ngFor="let bullet of bullets" (click)="goToSlide(bullet)" class="bullet"
           [class.active]="bullet === activeSlide"></div>
    </div>
  </div>
</div>
