import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { PageItem } from '../../page.interface';
import { PageBaseComponent } from '../page-base/page-base.component';

@Component({
  selector: 'ngx-sitemule-contact-cards',
  templateUrl: './contact-cards.component.html',
  styleUrls: ['./contact-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCardsComponent extends PageBaseComponent {
  @Input() layout: 'grid' | 'list' | 'scroll' = 'grid';
  @Input() logo: string = '/assets/logo.png';
  constructor(http: HttpClient, cdr: ChangeDetectorRef) {
    super(http, cdr);
  }
}

export interface ContactCardPageItem extends PageItem {
  layout: {
    type: 'list' | 'grid' | 'scroll';
  };
  content: {
    contacts: {
      name: string;
      title: string;
      email: string;
      phone: string;
      logo: string;
      image: string;
    }[];
  };
}
