import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SparePartsComponent } from './spare-parts.component';
import { SparePartComponent } from './spare-part/spare-part.component';

@NgModule({
  declarations: [SparePartsComponent, SparePartComponent],
  imports: [RouterModule, MatIconModule, CommonModule],
  exports: [SparePartsComponent, SparePartComponent],
  providers: [],
})
export class SparePartsModule {}
