import {
  Component,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import * as marked from 'marked';
import { Position, Teaser } from '../../../page.interface';
import { getPositonAsCss } from '../../../page.util';

@Component({
  selector: 'ngx-sitemule-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeaserComponent {
  @Input() teaser: Teaser;

  get image() {
    return this.teaser.images[0];
  }

  get imageUrl() {
    return this.image.url;
  }

  get icon() {
    return this.image.layout.icon;
  }

  get iconColor() {
    return this.image.layout.iconColor;
  }

  get useImageAsBackground() {
    return this.image.layout.useImageAsBackground;
  }

  get link() {
    return this.image.link;
  }

  get title() {
    return this.image.title;
  }

  get description() {
    return marked.parse(this.image.caption);
  }

  get border() {
    return this.teaser.composition.border ? '1px solid' : '';
  }

  get borderColor() {
    return this.teaser.composition.borderColor;
  }

  get backgroundColor() {
    return this.teaser.composition.backgroundColor;
  }

  get imageBackgroundColor() {
    return this.image.layout.useImageAsBackground ? '' : this.image.layout.backgroundcolor;
  }

  get textBackgroundColor() {
    return this.teaser.composition.textBackgroundColor;
  }

  get imagePositionStyle() {
    return this.getImagePositon(this.image.layout.imageposition);
  }

  get iconPositionStyle() {
    return this.getImagePositon(this.image.layout.iconPosition);
  }

  get buttonPositionStyle() {
    return this.getImagePositon(this.image.layout.buttonPosition);
  }

  get buttonText() {
    return this.image.buttonText;
  }

  get buttonBackgroundColor() {
    return this.image.layout.buttonBackgroundColor;
  }

  get buttonTextColor() {
    return this.image.layout.buttonTextColor;
  }

  getImagePositon(position: Position) {
    return getPositonAsCss(position);
  }
}

