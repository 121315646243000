import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbsList: any = [];
  prefix = ''
  private breadcrumbsSource = new BehaviorSubject(this.breadcrumbsList);
  public breadcrumbs = this.breadcrumbsSource.asObservable();

  constructor(private http: HttpClient) {
  }

  setBreadcrumbList(breadCrumbList, breadcrumbPrefix?: string) {
    breadCrumbList = breadCrumbList.map(s => {
      s.seourl = (breadcrumbPrefix == undefined ? '' : breadcrumbPrefix + '/') + s.seourl;
      return {...s};
    })
    if (!breadCrumbList) {
      return;
    }
    this.prefix = breadcrumbPrefix || '';

    this.breadcrumbsList = JSON.parse(JSON.stringify(breadCrumbList));
    this.breadcrumbsSource.next(this.breadcrumbsList);
  }


  getSizeOfBreadcrumbs() {
    return this.breadcrumbsList.length;
  }

  getBreadcrumbMenu(seoUrl) {
    return this.http.get('/cms/prd/listProductCatalogsBySeoUrl/' + encodeURIComponent(seoUrl))
      .pipe(map((res: any) => {
        // const newRes = res.items;
        res.items?.forEach(item => {
          // item.label = item.text;
          item.seourl = this.prefix + '/' + item.seourl;
          delete item.leaf;

          // delete item.text;
        });
        return res.items;
      }));
  }
}
