import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import {
  BrowserModule
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular";
import { DefaultTranslateConfig, MaterialModule, SitemuleCoreModule, SitemulePageModule, SitemuleUiModule } from '@sitemule/ngx-sitemule';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxLoadingModule } from "ngx-loading";
import { first } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from "./app.service";
import {
  ContextMenuFlesupportComponent
} from './components/content-menu-submenus/context-menu-flesupport/context-menu-flesupport.component';
import {
  ContextMenuLargeComponent
} from './components/content-menu-submenus/context-menu-large/context-menu-large.component';
import {
  ContextMenuProductComponent
} from './components/content-menu-submenus/context-menu-product/context-menu-product.component';
import { ContentMenuComponent } from './components/content-menu/content-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderAndFooterComponent } from './components/header-and-footer/header-and-footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PageStackComponent } from './components/page-stack/page-stack.component';
import { PreviewComponent } from './components/preview/preview.component';


function initializeKeycloak(keycloak: KeycloakService, appService: AppService, route: Router) {
  const stf = environment.oauth ?
    keycloak.init({
      config: {
        url: 'https://auth.logitrans.com' + '/auth',
        realm: 'my.logitrans.com',
        clientId: 'client'
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    }) : (environment.oauthTest ? keycloak.init({
      config: {
        url: 'https://auth.service.tools/auth',
        realm: 'my.logitrans.com',
        clientId: 'frontend'
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    }) : {});
  keycloak.keycloakEvents$.subscribe(e => {
    if (e.type === KeycloakEventType.OnAuthLogout) {
      location.reload();
    }
  });
  keycloak.keycloakEvents$.pipe(first()).subscribe({

    next: (e) => {
      if (e.type == KeycloakEventType.OnAuthSuccess || e.type == KeycloakEventType.OnAuthRefreshSuccess) {
        appService.loadUser().then(q => {

        }, x => {
          keycloak.logout('https://my.logitrans.com/user/account/missinginfo');
        });
      }
    }
  });

  return () => stf;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeaderAndFooterComponent,
    ContentMenuComponent,
    ContextMenuProductComponent,
    PreviewComponent,
    PageStackComponent,
    ContextMenuLargeComponent,
    ContextMenuFlesupportComponent
  ],
  imports: [
    NgxDocViewerModule,
    KeycloakAngularModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SitemulePageModule,
    MatDialogModule,
    MaterialModule,
    MatMenuModule,
    FormsModule,
    NgxLoadingModule.forRoot({}),
    SitemuleUiModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(DefaultTranslateConfig),
    SitemuleCoreModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    CookieService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    AppService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) =>
        () => appService.downloadInitialData(),
      deps: [AppService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AppService, Router]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {provide: MAT_DIALOG_DATA, useValue: {}},
  ],
})
export class AppModule {
}
