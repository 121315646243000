import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BasketDialogComponent } from './basket-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class BasketDialogService {
  constructor(
    private dialog: MatDialog,
  ) {}

  openBasket(productNumberToFocus?: string) {
    return this.dialog.open(BasketDialogComponent, {
      panelClass: 'ui-dialog',
      data: {
        productNumberToFocus,
      },
      position: { right: '0', top: '0', bottom: '0' },
    });
  }
}
