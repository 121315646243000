<div class="user-first-password-container ">

	<h3 >Indtast nyt kodeord</h3>

	<form [formGroup]="passwordForm" #form (ngSubmit)="submit()" class="form ">

							<div class="ui-input-form">
								<div class="label">Kodeord</div>
								<input type="text"  formControlName="passwordOne">
							</div>
							<div class="ui-input-form">
								<div class="label">Gentag kodeord</div>
								<input type="text" formControlName="passwordTwo">
                <div class="error" *ngIf="passwordForm.hasError('notSame') && !passwordForm.controls.passwordTwo.pristine">
                    Kodeordene er ikke ens.
                </div>
							</div>
							<b>{{successMessage}}</b>

					<div class="tab" style="justify-content: flex-end;">
						<button class="ui-button success" [disabled]="passwordForm.invalid || passwordForm.disabled" type="submit">Godkend & login</button>
					</div>
	</form>

</div>
