import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../services/sitemule-user.service';

@Component({
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent {
  currentSession$ = this.userService.getCurrentSession();
  passwordHasBeenChanged: boolean;
  passwordError: boolean;
  changePasswordMessage: string;
  primaryAddress$ = this.currentSession$.pipe(
    map((ses) => {
      if (ses.customer) {
        const addresses = ses.customer.addresses;
        const primary_billing_address = addresses.find((s) => s.primary_address && s.type.toLowerCase() === 'billing');
        return primary_billing_address || addresses[0];
      }
    })
  );
  sections$ = combineLatest([
    this.primaryAddress$,
    this.userService.getCurrentSession(),
  ]).pipe(map(([primaryAddress, session]) => {
    const firstSection = (() => {
      const customerProperties = session.customer?.properties
      const ret = {
        title: 'web.user.customerid',
        items: [
          [
            {
              label: 'web.user.userid',
              value: session.user?.email
            },
            {
              label: 'web.user.username',
              value: session.user?.name
            },
          ],
        ]
      };

      if (customerProperties) {
        ret.items.push([
          {
            label: 'web.user.companyCode',
            value: customerProperties.customergroupcode,
          },
          {
            label: 'web.user.customer.statusCode',
            value: customerProperties.customerstatus,
          },
        ])
      }

      return ret;
    })();

    const sectionSection = (() => {
      const customer = session.customer;
      const customerProperties = customer?.properties;
      const ret = {
        title: 'web.user.receipt',
        items: [
          [
            {
              label: 'web.user.customernumber',
              value: session.customer?.erp_id
            },
            {
              label: 'web.user.customername',
              value: session.customer?.name
            },
            {
              label: ' ',
               // Add space
               preserveMultiline: true,
            },
            {
              label: 'web.order.billingaddress',
              preserveMultiline: true,
              value: primaryAddress ? `${primaryAddress.name}
${primaryAddress.address_1}
${primaryAddress.country_code} - ${primaryAddress.country_code} ${primaryAddress.city}

${primaryAddress.email || ''}` : ' - '
            },
          ],
        ]
      };

      if (customerProperties) {
        ret.items.push([
          {
            label: 'web.user.customer.category',
            value: `${customerProperties.categoryid ? `${customerProperties.categoryid} - ` : ''}${customerProperties.category}`,
          },
          {
            label: 'web.user.customer.district',
            value: `${customerProperties.customerdistrictcode ? `${customerProperties.customerdistrictcode} - ` : ''}${customerProperties.customerdistrict}`,
          },
          {
            label: 'web.user.customer.group',
            value: `${customerProperties.customergroupcode ? `${customerProperties.customergroupcode} - ` : ''}${customerProperties.customergroup}`,
          },
          {
            label: 'web.user.customer.responsible',
            value: `${customer.kam} - ${customerProperties.salesresponsbleid}`,
          },
          {
            label: 'web.user.customer.currency',
            value: customerProperties.currency,
          },
        ])
      }

      return ret;
    })();
    return [
      firstSection,
      sectionSection,
    ];
  }));

  constructor(
    private userService: UserService
  ) {}

  changePassword = new UntypedFormGroup({
    currentPassword: new UntypedFormControl('', Validators.required),
    newPassword: new UntypedFormControl('', Validators.required),
    repeatPassword: new UntypedFormControl('', Validators.required),
  });

  submit() {
    const formVal = this.changePassword.value;
    if (formVal.newPassword === formVal.repeatPassword) {
      this.userService
        .changePassword(
          this.userService.currentuser.user_id,
          formVal.currentPassword,
          formVal.newPassword
        )
        .subscribe((res) => {
          this.changePassword.disable();
          if (res.user_id) {
            this.passwordError = false;
            this.passwordHasBeenChanged = true;
            this.changePassword.reset();
            //   this.changePasswordMessage = res.message;
          }
        });
    } else {
      this.passwordHasBeenChanged = false;
      this.passwordError = true;
      this.changePassword.controls['newPassword'].setErrors({ nomatch: true });
      this.changePassword.controls['repeatPassword'].setErrors({
        nomatch: true,
      });
      this.changePasswordMessage = 'New password must match';
    }
  }
}
