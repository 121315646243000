<h2>Multi-factor authentication Required</h2>
<mat-dialog-content>
  <mat-form-field>
    <input matInput [(ngModel)]="data.oneTimeKey" type="text">
  </mat-form-field>

</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data" >Ok</button>
</div>
