import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationComponentModule } from '@sitemule/ng-components/components/navigation';
import { SitemuleCoreModule } from '../../../core/sitemule-core.module';
import { SitemuleUiModule } from '../../../ui/sitemule-ui.module';
import { UserMenuComponent } from './user-menu.component';

@NgModule({
  declarations: [UserMenuComponent],
  imports: [CommonModule, SitemuleCoreModule, SitemuleUiModule, NavigationComponentModule],
  exports: [UserMenuComponent],
})
export class UserMenuModule {}
