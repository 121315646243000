import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {PdfViewerComponent} from "./components/pdf-viewer/pdf-viewer.component";

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(private dialog: MatDialog) { }
  openDocument(filepath) {
    event.preventDefault();
    event.stopPropagation();

    return this.dialog.open(PdfViewerComponent, {
      panelClass: ['ui-dialog'],
      data: filepath,
      position: { top: '0', bottom: '0' }
    });
  }
}
