<div class="list" *ngIf="listItem" (click)="open()">
  <div class="title">{{listItem.description}}</div>
  <div class="date">{{listItem.properties?.date | date: 'dd-MM-yyyy' }}</div>
  <button class="ui-button flat" (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="export()">
      <mat-icon>import_export</mat-icon>
      <span>{{ "listitem.download.excel" | translate }}</span>
    </button>
    <button mat-menu-item (click)="print()">
      <mat-icon>print</mat-icon>
      <span>Print</span>
    </button>
    <button mat-menu-item (click)="printListInfo()">
      <mat-icon>
        view_module
      </mat-icon>
      <span>Print info list</span>
    </button>
    <button mat-menu-item (click)="delete()">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>
      <button mat-menu-item (click)="copyListToBasket()">
        <mat-icon>add_shopping_cart</mat-icon>
        <span>Add to basket</span>
      </button>

  </mat-menu>
</div>
