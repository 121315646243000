<div class="video-container">
  <span>Ergonomic Lifting Equipment</span>
  <div class="video-container__links">
    <button class="link" (click)="login()">{{'web.homepage.login' | translate}}</button>
    <button class="link" (click)="register()">{{'web.homepage.register' | translate}}</button>
  </div>
  <div class="image">
    <img src="assets/mylogitrans-forside.png" />
  </div>
</div>
