import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(private title: Title, private meta: Meta) {}

  setTitleAndMeta({
    title,

    metaDescription,
    metaKeywords,
  }: {
    title: string;
    metaDescription?: string;
    metaKeywords?: string;
  }) {
    this.title.setTitle(title);

    if (metaDescription) {
      this.meta.updateTag({
        name: 'description',
        content: metaDescription,
      });
    }
    else {
      this.meta.removeTag('name="description"');
    }

    if (metaKeywords) {
      this.meta.updateTag({
        name: 'keywords',
        content: metaKeywords,
      });
    } else {
      this.meta.removeTag('name="keywords"');
    }
  }

  updateLanguage(lang: string) {
    document.documentElement.setAttribute('lang', lang);
  }
}
