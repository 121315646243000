import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SitemuleUserMatLoginComponent } from './mat-login.component';

@Injectable({ providedIn: 'root' })
export class LoginDialogGuard implements Resolve<void> {
  constructor(private matdialog: MatDialog) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    this.matdialog.open(SitemuleUserMatLoginComponent, {
      data: {
        token: route.params.token,
        dontRedirect: true,
        returnUrl: route.queryParams.returnUrl || '/user',
      },
      panelClass: 'small-dialog',
    });
    return of();
  }
}
