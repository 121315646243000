<div class="contact">
  <div *ngIf="card.image" class="contact__image">
    <img src="{{card.image}}" alt="{{card.name}}">
  </div>
  <div *ngIf="!card.image" class="contact__image">
    <img src="">
  </div>
  <div class="contact__details">
    <h3 class="contact__name">
      {{card.name}}
    </h3>
    <div class="contact__title">
      {{card.header}}
    </div>
    <div class="contact__phone" *ngIf="card.phone">
      {{'web.stack.card.tel' | lowercase | translate}} <a href='tel://{{card.phone}}'> {{card.phone}} </a>
    </div>
    <div class="contact__mobile" *ngIf="card.mobile">
      {{'web.stack.card.cell' | lowercase | translate}} <a href='tel://{{card.mobile}}'> {{card.mobile}} </a>
    </div>
    <div class="contact__email" *ngIf="card.email">
      <a href='mailto:{{card.email}}'> {{card.email}} </a>
    </div>
    <div class="contact__linkedIn" *ngIf="card.linkedIn">
      <a href='{{card.linkedIn}}'><i class="ui-icon-linkedin"></i> LinkedIn</a>
    </div>
  </div>
</div>


