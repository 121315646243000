import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldsModule } from '../fields/fields.module';
import { DynamicFormFieldComponent } from './components/dynamic-form-field/dynamic-form-field.component';
import { DynamicFormFieldGroupComponent } from './components/dynamic-form-field-group/dynamic-form-field-group.component';



@NgModule({
  declarations: [DynamicFormComponent, DynamicFormFieldComponent, DynamicFormFieldGroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    FieldsModule,
    ReactiveFormsModule
  ],
  exports: [DynamicFormComponent]
})
export class DynamicFormModule { }
