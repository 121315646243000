import {Component, Inject, OnInit} from '@angular/core';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {filter, first, tap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(private router: Router,
              public titleService: Title,
              public dialogRef: MatDialogRef<AppComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private dialog: MatDialog) {
    this.titleService.setTitle('Logitrans A/S')
    this.router.events.pipe(first()).subscribe((event) => {
      this.closeDialogsOnNavigation();
    });
  }
  closeDialogsOnNavigation(){
        this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationStart),
      tap(() => this.dialog.closeAll())
    ).subscribe();
  }
  ngOnInit() {
    const isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
    if (isIE) {
      this.dialog.open(DialogIE, {
        panelClass: 'dialogIE',
        width: '590px',//add, det her er ulækkert;
      });
    }
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-IE',
  template: `
    <h2 style="text-align: center;">Få en endnu bedre oplevelse af vores hjemmeside</h2>

    <p style="font-size: 15px; text-align: center">Du bruger en version af Internet Explorer, som ikke længere er
      understøttet. Microsoft
      beder alle om at
      opgradere til den nye og bedre Microsoft Edge.
    </p>

    <p style="font-size: 13px; text-align: center">Læs hvorfor og hvordan du selv kan opgradere her:
      <a href="https://www.microsoft.com/da-dk/microsoft-365/windows/end-of-ie-support">https://www.microsoft.com/da-dk/microsoft-365/windows/end-of-ie-support</a>
    </p>
    <div mat-dialog-actions style="text-align: center">
      <button class="ui-button" style="width: 100px; color:white;
           margin-top:10px;background-color:#003b6e" mat-button (click)="onNoClick()">Ok
      </button>
    </div>`
})

export class DialogIE {
  constructor(
    public dialogRef: MatDialogRef<DialogIE>,
    @Inject(MAT_DIALOG_DATA) public data) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
