<div class="price-list-container" [class.mobile]="mobileVersion">
  <div class="basket-list" *ngIf="basket">
    <div class="table-header-line">
      <div class="head-container">
        <div class="head" style="grid-column: 3;">
          {{'web.order.quantity' | translate }}
        </div>
        <div class="head">
          {{'web.order.price' | translate }}
        </div>
        <div class="head">
          {{'web.order.total' | translate }}
        </div>
      </div>
    </div>
    <div class="basket-item" *ngFor="let basketItem of basket.items">
      <div class="functions">
        <div class="inventory-status" [ngClass]="basketItem.inventoryStatus">
        </div>
        <div class="inventory">
          <ng-container *ngIf="basketItem.inventoryStatus == 'ON_THE_WAY'">{{'web.webshop.ontheway'|lowercase |
            translate}}</ng-container>
          <ng-container *ngIf="basketItem.inventoryStatus == 'SOLD_OUT'">{{'web.webshop.soldout'|lowercase |
            translate}}</ng-container>
        </div>

        <i class="icon-multiply delete" *ngIf="!readonly" (click)="remove(basketItem)"></i>
      </div>

      <img class="thumbnail" style="max-width: 100%;"
        src="/.0/cms/scaleImage.aspx?path={{basketItem.image}}&width=500&height=500" />
      <div class="info-text">
        <div class="title">
          {{basketItem.title}}
        </div>
        <p>{{'web.order.itemnumber' | translate }}: {{basketItem.productNumber}}</p>
      </div>
      <div class="amount">
        <input *ngIf="!readonly" placeholder="amount" #input (change)="update($event, basketItem)"
          class="ui-input {{basketItem.productNumber}}" min="0" step="1" type="number" [value]="basketItem.quantity">
        <span *ngIf="readonly">{{basketItem.quantity}}</span>
      </div>
      <div class="item-price">
        <ng-container *ngIf="mobileVersion">
          {{'web.order.price' | translate }}
        </ng-container> {{basketItem.pricePerUnit| sitemuleCurrency: 'financial'}}
      </div>
      <div class="total-price">
        {{(basketItem.price)| sitemuleCurrency: 'financial'}}
      </div>
    </div>
  </div>
  <div class="price" *ngIf="!hidePrice">
    <div class="content" *ngIf="basket">
      <div class="line" *ngIf="basket.discountAmount != 0 ">
        <span>{{'web.order.discount' | translate}}</span><span>{{basket.discountAmount | sitemuleCurrency: 'financial'}}</span>
      </div>
      <div class="line">
        <span>{{'web.order.totalprice' | translate }}</span><span>{{basket.totalAmountIncludingDiscount | sitemuleCurrency: 'financial'}}</span>
      </div>
      <div class="spacing-line"></div>
      <div class="line">
        <span>{{'web.order.delivery' | translate }}</span><span>{{basket.deliveryAmount | sitemuleCurrency: 'financial'}}</span>
      </div>
      <div class="line">
        <span>{{'web.order.vat' | translate }}</span><span>{{basket.vatAmount  | sitemuleCurrency: 'financial'}}</span>
      </div>
      <div class="final-price">
        <div class="line" *ngIf="basket">
          <b>{{'web.order.totalpriceinklvat' | translate }}</b><b>{{basket.totalAmount | sitemuleCurrency:
            'financial'}}</b>
        </div>
      </div>
    </div>
  </div>
</div>
