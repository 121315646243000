import {Component, Input} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-business-card-grid',
  templateUrl: './business-card-grid.component.html',
  styleUrls: ['./business-card-grid.component.scss']
})
export class BusinessCardGridComponent {
  @Input() data: { items, title, header };

  constructor() {
  }


}
