import { Router } from '@angular/router';
import { LinkTarget } from '../../page/page.interface';

//todo add callAction
export function navigateTo(
  link: string,
  route: Router,
  target: LinkTarget = ''
) {
  if (!link) return;

  // Checking for if tel, "call" tel.
  if (link.startsWith('tel:')) {
    window.open(link, '_top');
    return;
  }

  //open in new tab
  if (target === 'blank') {
    link = link.startsWith('/') ? window.location.origin + link : link;
    window.open(link, '_blank');
  } else {
    //open in current tab
    if (
      link.startsWith('/') ||
      link.toLowerCase().includes(window.location.host) ||
      !link.includes('.')
    ) {
      link = link.toLowerCase().includes(window.location.origin)
        ? window.location.pathname
        : link;
      route.navigate(['/' + link]);
    } else {
      window.location.href = link;
    }
  }
}

/**
 * @deprecated The method should not be used, use navigateTo instead and handle event there.
 */
export function handleNavigation(
  link: string,
  route: Router,
  target: LinkTarget = ''
) {
  navigateTo(link, route, target);
  event.stopPropagation();
  event.preventDefault();
}

export function handleCallToAction(action: any) {
  if (action.link) {
    if (action.link.type !== 'url') {
      window.open(action.link.url);
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  }
}
