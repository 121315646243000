<div class="main-header ">

  <img src="/assets/logo.png" (click)="goToFrontpage()" alt="" class="logo">

  <ng-container *ngIf="displayMenu">
    <div class="links">
      <ng-container *ngTemplateOutlet="links"></ng-container>

    </div>
    <div class="burger" (click)="isBurgerOpen = !isBurgerOpen" [class.active]="isBurgerOpen">
      <i [class]="isBurgerOpen ? 'icon-multiplylight' : 'icon-list'"></i>
      <div class="mobile-menu" *ngIf="isBurgerOpen" [style.top.px]="isRedBarClosed ? 100 : 100">
        <ng-container *ngTemplateOutlet="links"></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #links>
  <div *ngFor="let menuItem of menuList" class="link" [ngClass]="{'activeItem':menuItem === activeMenu}"
       (click)="navigateTo(menuItem)">{{'web.header.links.' + menuItem.label |lowercase | translate}}<i
    class="icon-arrowright">
  </i>
  </div>
  <ng-container *ngIf="$userLoggedIn | async as user; else login">


<!--    TODO: Hacky hacky solution, to change color and text on login-->
    <div class="link user  ui-button-round" style="display: flex; align-items: center; border:none; width: 100px"
         [style.background]="user.customer?.properties?.categoryid == 'PREM' ? '#878681' : user.customer?.properties?.categoryid == 'GOLD' ? '#d4af37' : user.customer?.properties?.categoryid == 'SILV' ? '#c0c0c0' : '#35506E'"
         [matMenuTriggerFor]="menu">
      <span *ngIf="user.customer?.properties?.categoryid == 'PREM'" style="padding-right: 5px; border:none;">Premium</span>
      <span *ngIf="user.customer?.properties?.categoryid == 'GOLD'" style="padding-right: 5px; border:none;">Gold</span>
      <span *ngIf="user.customer?.properties?.categoryid == 'SILV'" style="padding-right: 5px; border:none;">Silver</span>
      <span *ngIf="user.customer?.properties?.categoryid == 'DEA'" style="padding-right: 5px; border:none;">Dealer</span>


      <i [class.active]="showDropdown" style="display: block; " class="icon-profile"></i>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let item of showDropdownMenu$ | async"
                (click)="navigateTo(item)">{{item.label}}</button>

        <button mat-menu-item [matMenuTriggerFor]="menuLanguage" >{{'web.homepage.menu.language' | translate}}</button>
      </mat-menu>
      <mat-menu #menuLanguage="matMenu">
        <button mat-menu-item *ngFor="let language of languages"
                (click)="selectLanguage(language)">{{language.toUpperCase()}}</button>

      </mat-menu>

      <span style="font-size: 11px;">{{user.user?.usrnam}}</span>
    </div>
  </ng-container>

  <ng-template #login>
    <ng-container *ngIf="isBurgerOpen">
      <div class="link" (click)="loginDialog()">{{'web.homepage.login' | translate}} <i class="icon-arrowright"></i>
      </div>
    </ng-container>
    <ng-container *ngIf="!isBurgerOpen">
      <div class="link" style="display: inherit;
      align-items: center;" (click)="loginDialog()">{{
        'web.homepage.login' | translate}}
      </div>
    </ng-container>
  </ng-template>
</ng-template>


