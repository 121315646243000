import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BasketServiceV2 } from '../../../core/basket/basketv2.service';
import { Customer } from '../../models/User';
import { UserService } from '../../services/sitemule-user.service';

@Component({
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss'],
})
export class CustomersPageComponent implements OnInit {
  customersSource = new BehaviorSubject([]);
  employeesSource = new BehaviorSubject([]);
  searchForm = new UntypedFormGroup({
    category: new UntypedFormControl(0),
    search: new UntypedFormControl(''),
  });

  originalUser$ = this.userService.originalUser$;

  employees$ = combineLatest([
    this.employeesSource.asObservable(),
    this.userService.currentuser$,
  ]).pipe(
    map((d) => {
      const employees = d[0];
      // const employees = (d[0][d[0].length - 1] = {name: d[1].name, id: 2});
      return employees;
    })
  );

  customers$ = combineLatest([
    this.customersSource.asObservable(),
    this.searchForm.valueChanges.pipe(startWith({})),
  ]).pipe(
    map((d) => {
      let customers: Customer[] = d[0];
      const form = d[1];
      if (form.search) {
        customers = customers.filter((s) =>
          JSON.stringify(s).toLowerCase().includes(form.search.toLowerCase())
        );
      }
      if (form.category == 1) {
        customers = customers.filter(
          (s) =>
            s.kam?.toLowerCase() ===
            this.userService.currentuser.name.toLowerCase()
        );
      }
      return customers;
    })
  );
  active;

  constructor(
    private userService: UserService,
    private basketService: BasketServiceV2,
  ) {}

  ngOnInit(): void {
    this.userService
      .getCustomers()
      .subscribe((s) => this.customersSource.next(s));
  }

  workAsClientCustomer(evt: Event, _customer, employee?) {
    evt.stopPropagation();
    this.userService.setSellerAsClientCustomer(employee.client_id)
      .subscribe(() => {
        this.basketService.loadBasket();
      })
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  workAsCustomer(evt: Event, customer) {
    evt.stopPropagation();
    this.userService.setSellerAsCustomer(customer.customer_id)
      .subscribe(() => {
        this.basketService.loadBasket();
      })
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  initEmployees(customer) {
    this.active = customer;
    this.employeesSource.next([]);
    this.userService
      .getEmployees(customer.customer_id)
      .subscribe((e: any) => this.employeesSource.next(e));
  }
}
