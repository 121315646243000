<div class="user-customers-container">
  <form class="filter-bar" [formGroup]="searchForm">
    <select class="ui-input" formControlName="category">
      <option value="0">Vis alle</option>
      <option value="1">Vis egne</option>
    </select>
    <div class="ui-input-icon-group">
      <i class="icon-Soeg"></i>
      <input #searchEl type="text" #input formControlName="search" placeholder="Søg på dine kunder her"
      >
    </div>
  </form>
  <div class="customers">
    <div class="customer" *ngFor="let customer of customers$ | async" (click)="initEmployees(customer)"
         [class.active]="customer == active">
      <div class="title">{{customer.name}}</div>
      <div class="closed">{{customer.addresses[0]?.address_1}} / {{customer.cvr}}</div>
      <div class="opened">
        <div class="left">
          <p>Kunde:</p>
          <p>{{customer.name}}</p>
          <p>Kundenr:</p>
          <p>{{customer.erp_id}}</p>
          <p>Cvr:</p>
          <p>{{customer.cvr}}</p>
          <p>Att:</p>
          <p>{{customer.reference}}</p>
          <p>Adresse:</p>
          <p>{{customer.addresses[0]?.address_1}}</p>
          <p>Telefon:</p>
          <p>{{customer.addresses[0]?.phone}}</p>
        </div>
        <div class="right">
          <div class="clients">
            <i class="material-icons">person</i>
            <p *ngIf="originalUser$ | async as originalUser" (click)="workAsCustomer($event, customer)">{{originalUser.name}}</p>
          </div>
          <ng-container *ngFor="let employee of employees$ | async">
            <div class="clients">
              <i class="material-icons-outlined">person</i>
              <p (click)="workAsClientCustomer($event, customer, employee)">{{employee.usrnam}}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
