import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translationKeyGenerator'
})
export class TranslationKeyGeneratorPipe implements PipeTransform {
  public transform(value: string): string {
    const trimmedValue = value && value.trim() || '';

    if (!trimmedValue) {
      return '';
    }

    return `web.${trimmedValue.split(' ').filter(x => x).map(x => x.toLowerCase()).join('.')}`;
  }
}
