import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { switchMap } from 'rxjs/operators';
import { UserService } from '../../../services/sitemule-user.service';

@Component({
  templateUrl: './first-password-page.component.html',
  styleUrls: ['./first-password-page.component.scss'],
})
export class FirstPasswordPageComponent {
  passwordForm = new UntypedFormGroup(
    {
      passwordOne: new UntypedFormControl('', Validators.required),
      passwordTwo: new UntypedFormControl('', Validators.required),
    },
    { validators: this.checkPasswords }
  );

  successMessage = '';
  ticket;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { ticket: string },
    private userService: UserService
  ) {
    this.ticket = data.ticket;
  }
  submit() {
    if (this.passwordForm.valid) {
      this.passwordForm.disable();
      this.userService
        .setNewPassword(this.ticket, this.passwordForm.value.passwordOne)
        .pipe(
          switchMap((rslt: any) => {
            return this.userService.login(
              rslt.profile_id,
              this.passwordForm.value.passwordOne
            );
          })
        )
        .subscribe();
    }
  }

  checkPasswords(group: UntypedFormGroup) {
    // here we have the 'passwords' group
    const password = group.get('passwordOne').value;
    const confirmPassword = group.get('passwordTwo').value;
    return password === confirmPassword ? null : { notSame: true };
  }
}
