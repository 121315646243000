import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ngx-sitemule-pdf-viewer',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  url;
  src;

  viewer: 'google' | 'office' | 'url' | 'pdf' = 'google';

  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {
    let url = data.toLowerCase();
    this.setViewerBasedOnUrl(url);
    if (!url.includes('http')) {
      // if (!Environment.production) {
      //   url = 'https://lgt6153.proxy.sitemule.com/' + url;
      // } else {
      url = window.location.origin + url;
      // }
    }
    this.url = url;
  }

  download(){
    window.open(this.url);
  }

  setViewerBasedOnUrl(url: string) {
    if (url.endsWith('png') ||
      url.endsWith('jpg') ||
      url.endsWith('jpeg') ||
      url.endsWith('gif')) {
      this.viewer = 'url';
    }
    //check for office types
    if (
      url.endsWith('.doc') ||
      url.endsWith('.docx') ||
      url.endsWith('.xls') ||
      url.endsWith('.xlsx') ||
      url.endsWith('.ppt') ||
      url.endsWith('.pptx')
    ) {
      this.viewer = 'office';
      this.src = url;
    }
    if (url.endsWith('.pdf')) {
      this.viewer = 'pdf';
    }
  }
}
