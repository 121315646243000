import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { PageItem } from '../../page.interface';
import { PageBaseComponent } from '../page-base/page-base.component';
import { ITablePdata } from './table-p/table-p.component';

@Component({
  selector: 'ngx-sitemule-table-basic',
  templateUrl: './table.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends PageBaseComponent implements OnChanges {
  @Output() rowClickOut = new EventEmitter();
  @Output() lazyLoadEmit = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    http: HttpClient
  ) {
    super(http, cdr);
  }
  color = undefined;
  //the height of the rows. TODO: make it look at the dom runtime and not hardcoded
  heightOfRow = 56;
  ngOnChanges(changes: SimpleChanges): void {
    const data: TablePageItem = changes.data.currentValue;
    if (data) {
      let color = data.composition?.color;
      if (color) {
        this.color = color;
        this.elementRef.nativeElement.style.setProperty('--color', color);
      }

      data.options.scrollHeight =
        data.options.scrollHeightBasedOnElements &&
        data.rowData.length > data.options.scrollHeightBasedOnElements
          ? this.heightOfRow +
            this.heightOfRow * data.options.scrollHeightBasedOnElements +
            'px'
          : data.options.scrollHeight;
    }
  }
  getScrollable() {}
  tableRowClick(row) {
    this.rowClickOut.emit(row);
  }
  lazyLoadfn(evt) {
    this.lazyLoadEmit.emit(evt);
  }
}
export interface TablePageItem extends ITablePdata, PageItem {
  composition?: {
    color?: string;
  };
  sum: {
    preText?: string;
    bigText?: string;
    postText?: string;
  };
}
