import { Component } from '@angular/core';

@Component({
  templateUrl: './copy-order-to-basket.component.html',
  styleUrls: ['./copy-order-to-basket.component.scss']
})
export class CopyOrderToBasketComponent {

  constructor() { }

}
