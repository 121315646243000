import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  debounceTime,
  distinctUntilKeyChanged,
  switchMap,
} from 'rxjs/operators';
import { UserService } from '../../../services/sitemule-user.service';

@Component({
  templateUrl: './create-account-page.component.html',
  styleUrls: ['./create-account-page.component.scss'],
})
export class CreateAccountPageComponent implements OnDestroy {
  selectedTabIndex = 0;

  hasSubmitted = false;
  accountForm = new UntypedFormGroup({
    company: new UntypedFormGroup({
      cvr: new UntypedFormControl(''),
      companyName: new UntypedFormControl('', Validators.required),
      addressOne: new UntypedFormControl('', Validators.required),
      addressTwo: new UntypedFormControl(''),
      zipcode: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      phoneNumber: new UntypedFormControl(''),
      email: new UntypedFormControl('', [Validators.email]),
    }),
    user: new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.email,
        Validators.required,
      ]),
      name: new UntypedFormControl('', Validators.required),
      title: new UntypedFormControl(''),
      phoneNumber: new UntypedFormControl(''),
      mobileNumber: new UntypedFormControl('', [Validators.required]),
    }),
  });
  doesEmailExist = this.accountForm.controls.user.valueChanges
    .pipe(
      debounceTime(1000),
      distinctUntilKeyChanged('email'),
      switchMap((value) => {
        return this.userService.doesEmailExist(value.email);
      })
    )
    .subscribe((doesEmailExist) => {
      if (doesEmailExist) {
        this.accountForm.controls.user
          .get('email')
          .setErrors({ notUnique: true });
      }
    });

  findFromCVR() {
    let val = this.accountForm.controls.company.value.cvr;
    if (!val) {
      return;
    }
    this.userService.getCVRByNumber(val).subscribe(
      (data) => {
        if (data) {
          const companyName = data.virksomhedMetadata?.nyesteNavn?.navn || '';
          const addressOne =
            data.beliggenhedsadresse[data.beliggenhedsadresse.length - 1]
              ?.vejnavn || '';
          const addressTwo =
            data.beliggenhedsadresse[data.beliggenhedsadresse.length - 1]
              ?.husnummerFra || '';
          const zipcode =
            data.beliggenhedsadresse[data.beliggenhedsadresse.length - 1]
              ?.postnummer || '';
          const city =
            data.beliggenhedsadresse[data.beliggenhedsadresse.length - 1]
              ?.postdistrikt || '';
          const country =
            data.beliggenhedsadresse[data.beliggenhedsadresse.length - 1]
              ?.landekode || '';
          const phoneNumber = data.telefonNummer[0]?.kontaktoplysning || '';
          const email = data.elektroniskPost[0]?.kontaktoplysning || '';
          this.accountForm.controls.company.patchValue({
            companyName,
            addressOne,
            addressTwo,
            zipcode,
            city,
            country,
            phoneNumber,
            email,
          });
        }
      },
      () => {
        this.accountForm.controls.company
          .get('cvr')
          .setErrors({ doesntExist: true });
      }
    );
  }
  successMessage = '';
  constructor(private location: Location, private userService: UserService) {}

  ngOnDestroy() {
    this.doesEmailExist.unsubscribe();
  }
  goback() {
    this.location.back();
  }
  goToStep(selectedTabIndex: number) {
    switch (selectedTabIndex) {
      case 0:
        this.selectedTabIndex = selectedTabIndex;
        break;
      case 1:
        if (this.accountForm.controls.user.valid) {
          this.selectedTabIndex = selectedTabIndex;
        }
        break;
      default:
        break;
    }
    this.accountForm.controls.user.markAllAsTouched();
  }
  submit() {
    this.accountForm.markAllAsTouched();
    if (this.accountForm.valid) {
      this.accountForm.disable();
      this.userService.createUser(this.accountForm.value).subscribe(() => {
        this.hasSubmitted = true;
      });
    }
  }
}
