import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../../services/currency.service';

@Pipe({
  name: 'sitemuleCurrency',
  pure: true
})
export class SitemuleCurrencyPipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {
  }

  transform(value, showType?: 'financial' | 'pretty') {
    return this.currencyService.transform(value || 0, showType);
  }
}
