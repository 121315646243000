import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserService } from '../services/sitemule-user.service';

@Injectable()
export class AuthorizedInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => {
      return this.handleResponseError(error, request, next);
    }));
  }

  handleResponseError(error, request, next?) {
    if (error.status === 401) {
      const userService = this.injector.get(UserService);
      userService.showLoginDialog().pipe(switchMap(loginResult => {
        if (loginResult) {
          return next.handle(request);
        }
      }));
    } else {
      return throwError(() => error);
    }
  }
}
