import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NotificationService } from '../../../../core/services/notification.service';
import { UserService } from '../../../services/sitemule-user.service';

@Injectable({ providedIn: 'root' })
export class LogoutDialogGuard implements Resolve<void> {
  constructor(
    private userService: UserService,
    private notificationService: NotificationService
  ) {}
  resolve(): Observable<any> | Promise<any> | any {
    this.userService.logout().subscribe((res: any) => {
      if (res.success) {
        localStorage.removeItem('CURRENT_SESSION');
      }
      this.notificationService.show(res.message, 'error');
      window.location.replace('/');
    });
    return of();
  }
}
