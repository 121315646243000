import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AccordionInterface } from './accordion.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input() accordion: AccordionInterface;
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  constructor(public el: ElementRef) {}

  onClick(event) {
    this.toggle.emit(event);
  }
}
