import { Position } from "./page.interface";

export function getPositonAsCss(position: Position): string {
	const horizontal = position[0];
	const vertical = position[1];

	//do horizontal
	let horizontalString = 'align-items:'
	switch (horizontal) {
		case 't':
			horizontalString += 'start';
			break;
		case 'l':
		case 'c':
		case 'r':
			horizontalString += 'center';

			break;
		case 'b':
			horizontalString += 'end';
			break;
		default:
			break;


	}

			horizontalString += ';';
	//do vertical
	let verticalString = 'justify-content:'
	switch (vertical) {
		case 'l':
			verticalString += 'start';
			break;
		case 'c':
			verticalString += 'center';

			break;
		case 'r':
			verticalString += 'end';
			break;
		default:
			break;
	}
		verticalString	 += ';';

		return horizontalString + verticalString;


}
