import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '@sitemule/ng-components/components/button';
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { SitemuleCoreModule } from '../core/sitemule-core.module';
import { AccordionComponent } from './components/accordion/accordion.component';
import { BurgerMenuComponent } from './components/animated-icons/burger-menu/burger-menu.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './components/breadcrumb/breadcrumb.service';
import { BusinessCardGridComponent } from './components/business-card-grid/business-card-grid.component';
import { BusinessCardComponent } from './components/business-card-grid/business-card/business-card.component';
import { DialogComponent } from './components/dialog/dialog/dialog.component';
import { InfoDialogComponent } from './components/dialog/info-dialog/info-dialog.component';
import { DynamicFieldComponent } from './components/dynamic-form-group/dynamic-field/dynamic-field.component';
import { DynamicFormGroupComponent } from './components/dynamic-form-group/dynamic-form-group.component';
import { HeaderMsgComponent } from './components/header-msg/header-msg.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { MapComponent } from './components/map/map.component';
import { MenuComponent } from './components/menu/menu.component';
import { PdfViewerComponent } from "./components/pdf-viewer/pdf-viewer.component";
import { PinchZoomModule } from './components/pinch-zoom/pinch-zoom.module';
import { SliderComponent } from './components/slider/slider.component';
import { TabComponent } from './components/tab/tab.component';
import { TooltipDirective } from './components/tooltip/tooltip.directive';
import { DynamicFormModule } from './modules/dynamic-form/dynamic-form.module';
import { UiService } from "./ui.service";


@NgModule({
  declarations: [
    SliderComponent,
    ImageSliderComponent,
    TabComponent,
    MenuComponent,
    BreadcrumbComponent,
    AccordionComponent,
    BusinessCardComponent,
    BusinessCardGridComponent,
    DynamicFieldComponent,
    DynamicFormGroupComponent,
    BurgerMenuComponent,
    MapComponent,
    DialogComponent,
    TooltipDirective,
    InfoDialogComponent,
    HeaderMsgComponent,
    PdfViewerComponent,
  ],
  imports: [
    PinchZoomModule,
    CommonModule,
    ReactiveFormsModule,
    SitemuleCoreModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    TranslateModule.forChild(),
    NgxDocViewerModule,
    DynamicFormModule,
    ButtonModule,
  ],
  exports: [
    SliderComponent,
    ImageSliderComponent,
    TabComponent,
    MenuComponent,
    BreadcrumbComponent,
    AccordionComponent,
    BusinessCardGridComponent,
    DynamicFormGroupComponent,
    BurgerMenuComponent,
    HeaderMsgComponent,
    MapComponent,
    DialogComponent,
    TooltipDirective,
    PdfViewerComponent,
  ],
  providers: [UiService]
})
export class SitemuleUiModule {
  static forRoot(): ModuleWithProviders<SitemuleUiModule> {
    return {
      ngModule: SitemuleUiModule,
      providers: [
        {provide: BreadcrumbService}
      ]
    };
  }
}
