import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeda from '@angular/common/locales/da';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from "@sitemule/ng-components/pipes/marked";
import { DelayedHoverDirective } from './directives/delayedHover/delayed-hover.directive';
import { DateFormat2Pipe } from "./pipes/date-format.pipe";
import { SafeSanitizerPipe } from './pipes/domSanitizer/safeSanitizer.pipe';
import { SitemuleCurrencyPipe } from './pipes/sitemule-currency/sitemule-currency.pipe';
import { TranslationKeyGeneratorPipe } from './pipes/translationKeyGenerator/translationKeyGenerator.pipe';

registerLocaleData(localeda);

@NgModule({
  declarations: [
    SitemuleCurrencyPipe,
    SafeSanitizerPipe,
    DateFormat2Pipe,
    DelayedHoverDirective,
    TranslationKeyGeneratorPipe,
  ],
  imports: [TranslateModule.forChild(), MarkdownModule],
  exports: [
    SitemuleCurrencyPipe,
    SafeSanitizerPipe,
    DelayedHoverDirective,
    TranslationKeyGeneratorPipe,
    DateFormat2Pipe,
  ],
  providers: [CurrencyPipe],
})
export class SitemuleCoreModule {}
