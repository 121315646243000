import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  Provider,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateField } from '../../fields.model';

const SELECT_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateComponent),
  multi: true,
};

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SELECT_CONTROL_VALUE_ACCESSOR],
})
export class DateComponent implements ControlValueAccessor {
  @Input() field!: DateField;

  value: any;
  disabled = false;

  private onChange?(value): void;
  // @ts-ignore
  private onTouched?(): void;

  constructor(private cdf: ChangeDetectorRef) {}

  writeValue(date: any): void {
    this.value = date;
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdf.markForCheck();
  }
}
