import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-sitemule-page-base',
  templateUrl: './page-base.component.html',
  styleUrls: ['./page-base.component.css'],
})
export class PageBaseComponent {
  constructor(private _http: HttpClient, private _cdr: ChangeDetectorRef) {}
  private _data: any = {};
  @Input() set data(value) {
    this._data = value;
    if (value.url) {
      this.resolveUrl(value.url);
    }
  }
  get data() {
    return this._data;
  }

  resolveUrl(url) {
    this._http.get(url).subscribe((s) => {
      this._data = mergeDeep(this._data, s);
      this._cdr.detectChanges();
    });
  }
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return mergeDeep(target, ...sources);
}
