import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules, RouterModule, Routes
} from '@angular/router';
import { HeaderAndFooterComponent } from './components/header-and-footer/header-and-footer.component';
import { PageStackComponent } from './components/page-stack/page-stack.component';
import { LanguageRedirectorGuard } from "./interceptors/language-redirector.guard";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
  preloadingStrategy: PreloadAllModules
};

// const IntRoute = [
//   {
//     path: '',
//     loadChildren: () =>
//       import('sitemule-webshop').then((mod) => mod.SitemuleWebshopModule),
//   },
// ];
const logRoute = [
  {
    path: '',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
];

// const accountRouter = [
//   {
//     path: 'account',
//     loadChildren: () => import('./user/account/account.module').then(m => m.AccountModule)
//   },
// ]
const childRoutes = [
  {
    path: 'user',
    children: logRoute,
  },
  // {
  //   path: 'account',
  //   children: accountRouter
  // },
  {
    path: 'faq', loadChildren: () => import('./user/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: ':seo',
    children: [
      {
        path: '',
        component: PageStackComponent,
      },
      {
        path: ':seo2',
        children: [
          {
            path: '',
            component: PageStackComponent,
          },
          {
            path: ':seo3',
            children: [
              {
                path: '',
                component: PageStackComponent,
              },
              {
                path: ':seo4',
                component: PageStackComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: PageStackComponent,
  },
];

const routes: Routes = [

  { // language matcher -> /{da | en}/products/testudstyr-og-transducere
    matcher: (url) => {
      return url && url[0] && url[0].path.length === 2 ? {consumed: url.slice(0, 1)} : null;
    },
    component: HeaderAndFooterComponent,
    children: childRoutes,
  },
  {
    path: '',
    canActivate: [LanguageRedirectorGuard],
    component: HeaderAndFooterComponent,
    children: childRoutes,
  },
  {path: 'dashboard', loadChildren: () => import('./user/dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path: 'customer', loadChildren: () => import('./user/customer/customer.module').then(m => m.CustomerModule)},
  {path: 'leadtime', loadChildren: () => import('./user/leadtime/leadtime.module').then(m => m.LeadtimeModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
