import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { navigateTo } from '../../../core/utility/routeNavigation';

import { Teaser, TeaserStack } from '../../page.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-teaser-stack',
  templateUrl: './teaser-stack.component.html',
  styleUrls: ['./teaser-stack.component.scss'],
})
export class TeaserStackComponent {
  _teaserStack: TeaserStack;
  teasers: Teaser[] = [];
  @Input() set teaserStack(teaserStack: TeaserStack) {
    this._teaserStack = teaserStack;
    if (teaserStack) {
      this.teasers = this.checkForShuffleAndMaxShownElements(teaserStack);
    }
  }
  get teaserStack() {
    return this._teaserStack;
  }

  constructor(private route: Router) {}

  checkForShuffleAndMaxShownElements(teaserStack: TeaserStack): Teaser[] {
    let teasers: Teaser[] = teaserStack.items;
    teasers = teaserStack.composition.shuffle
      ? teasers.sort(() => Math.random() - 0.5)
      : teasers;
    if (teaserStack.composition.columns && teaserStack.composition.rows) {
      const takeAmount =
        teaserStack.composition.columns * teaserStack.composition.rows;
      teasers = teasers.slice(0, takeAmount);
    }
    return teasers;
  }

  onTeaserClick(teaser: Teaser, event: MouseEvent) {
    const link = teaser.images[0];
    if (link) {
      navigateTo(link.link, this.route, link.target);
    }
    event.stopPropagation();
    event.preventDefault();
  }
}
