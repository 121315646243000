import {Component, Input} from '@angular/core';
import {BusinessCardInterface} from './business-card.interface';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss']
})
export class BusinessCardComponent {
  @Input() card: BusinessCardInterface;

  constructor() {
  }

}
