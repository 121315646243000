import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FirstPasswordPageComponent } from './first-password-page.component';

@Injectable({ providedIn: 'root' })
export class FirstPasswordDialogGuard implements Resolve<void> {
  constructor(private matdialog: MatDialog, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    this.matdialog.open(FirstPasswordPageComponent, {
      data: { ticket: route.params.ticket },
      panelClass: 'small-dialog',
    });
    this.router.navigateByUrl('');
    return of();
  }
}
