import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './components/select/select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { DateComponent } from './components/date/date.component';



@NgModule({
  declarations: [SelectComponent, RadioGroupComponent, DateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [SelectComponent, RadioGroupComponent, DateComponent]
})
export class FieldsModule { }
