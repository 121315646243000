import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
  loginData?: any;
  title?: string;
  content?: string;
  placeholder?: string;
  input: string;
}

@Component({
  selector: 'ui-dialog',
  templateUrl: 'dialog.component.html',
})
export class DialogComponent {

  input: string;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(input): void {
    this.dialogRef.close(input);
  }

}
