<button class="accordion " [class.opened]="accordion.opened" [disabled]="accordion.disabled"
        [style.display]="accordion.hidden ? 'none' : 'flex'"
        [ngStyle]="(accordion.layout?.buttonStyle ? accordion.layout?.buttonStyle : {})" (click)="onClick(accordion); ">
  <div class="left">
    <div #headerRef>
      <ng-content select="[left]">
      </ng-content>
    </div>
    <ng-container *ngIf="!headerRef.innerHTML.trim()">
      {{accordion.header}}
    </ng-container>
  </div>
  <div class="right">
    <div #ref>
      <ng-content select="[right]">
      </ng-content>
    </div>
    <ng-container *ngIf="!ref.innerHTML.trim()">
      <span *ngIf="accordion.opened"><i class="material-icons">expand_less</i></span>
      <span *ngIf="!accordion.opened"><i class="material-icons">expand_more</i></span>
    </ng-container>
  </div>
</button>
<div class="panel" [style.display]="(accordion.opened ? 'block' : 'none')"
     [style.width]="(accordion.layout?.buttonStyle ? accordion.layout?.buttonStyle.width : '100%')">
  <ng-content>
  </ng-content>
</div>
