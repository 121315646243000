<mat-card>
  <mat-card-title>
    <h3 style="margin-bottom: 10px;">Glemt kodeord?</h3>
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input matInput placeholder="E-mail" type="text" formControlName="username">
      </mat-form-field>
      <div style="text-align: center;">
        <button mat-raised-button color="primary">Send Link</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
