import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import {
  debounceTime,
  filter,
  first,
  map, switchMap,
  tap
} from 'rxjs/operators';
import {
  IGetProduct
} from '../../../../core/basket/basket.interfaces';
import { NotificationService } from '../../../../core/services/notification.service';
import { WebshopService } from '../../../../core/services/webshop.service';
import { DialogComponent } from '../../../../ui/components/dialog/dialog/dialog.component';
import { Line, ListService, SingleList } from '../../../services/list.service';
import { UserService } from '../../../services/sitemule-user.service';

const leadZero = (num: number) => {
  if (num < 10 && num >= 0) {
    return `0${num}`;
  }

  return `${num}`;
}

@Component({
  templateUrl: './edit-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./edit-list.component.scss'],
})
export class EditListComponent {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: any;
  currentSession$ = this.userService.getCurrentSession();
  primaryAddress$ = this.currentSession$.pipe(
    map((ses) => {
      if (ses.customer) {
        const addresses = ses.customer.addresses;
        return addresses.find((s) => s.primary_address)
          ? addresses.find((s) => s.primary_address)
          : addresses[0];
      }
    })
  );

  list$ = new BehaviorSubject<SingleList>(undefined);
  combinedList$ = new BehaviorSubject<any[]>([]);
  sortedList$: Observable<any[]> = this.combinedList$.pipe(
    map((list) => list.sort((a, b) => a.sequence - b.sequence))
  );
  updateLineDebounce = new BehaviorSubject({ line: undefined });
  searchProductInput = new BehaviorSubject({ value: '', trigger: undefined });
  filteredProducts$ = this.searchProductInput.pipe(
    debounceTime(750),
    switchMap((test) => {
      return merge(
        of([]),
        of(test).pipe(
          filter((info) => info && info.value?.length > 2),
          switchMap((query) =>
            this.webshopService.doProductSearch(query.value, 5, true).pipe(
              map((d: any) => {
                query.trigger.openPanel();
                return d.items;
              })
            )
          )
        )
      );
    })
  );

  updatingElement = false;

  constructor(
    private dialogref: MatDialogRef<EditListComponent>,
    private listService: ListService,
    private notificationService: NotificationService,
    private matDialog: MatDialog,
    private userService: UserService,
    private webshopService: WebshopService,
    @Inject(MAT_DIALOG_DATA) public data: EditListDialog
  ) {
    this.listService
      .getList(data.listId)
      .pipe(
        tap((list) => {
          this.list$.next(list);
          this.combinedList$.next([...list.lines]);
        })
      )
      .subscribe();
    this.updateLineDebounce
      .pipe(
        debounceTime(500),
        tap((s) => {
          if (!s.line || this.updatingElement) {
            return;
          }
          s.line.list_id = this.list$.getValue().list_id;
          this.updatingElement = true;
          this.listService
            .upsertListLine(s.line.list_line_id, s.line)
            .pipe(
              tap((line) => {
                this.updatingElement = false;
                if (!s.line.list_line_id) {
                  const existingLines = this.combinedList$.getValue();
                  let lineToOverWrite = existingLines.find(
                    (existing) => existing == s.line
                  );
                  lineToOverWrite.list_line_id = line.list_line_id;
                }
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  drop(event: CdkDragDrop<string[]>) {
    this.moveItemInArray(
      this.combinedList$.getValue(),
      event.previousIndex,
      event.currentIndex
    );
  }

  //do http request and update the list locally
  moveItemInArray(items, prevIndex, currentIndex) {
    var element = items[prevIndex];
    //do http request
    items.splice(prevIndex, 1);
    items.splice(currentIndex, 0, element);
    const prevSequence = items[items.indexOf(element) - 1]?.sequence || 0;
    const nextSequence =
      items[items.indexOf(element) + 1]?.sequence ||
      items[items.indexOf(element) - 1]?.sequence + 100 ||
      100;
    element.sequence = prevSequence + (nextSequence - prevSequence) / 2;
    this.listService
      .setLineSequence([element.list_line_id], prevSequence, nextSequence)
      .subscribe();
    this.combinedList$.next(items);
    //update the local list
  }

  dateSelected(value: Date) {
    const list = this.list$.getValue();
    list.properties.date = `${value.getFullYear()}-${leadZero(value.getMonth() + 1)}-${leadZero(value.getDate())}`;
    this.listService.createOrUpdateList(list.list_id, list).subscribe();
  }

  displayFn(product): string {
    return product.label;
  }

  updateList() {
    const list = this.list$.getValue();
    this.listService.createOrUpdateList(list.list_id, list).subscribe();
  }

  addTextInput() {
    this.sortedList$.pipe(first()).subscribe((s) => {
      const seqeunceFromLastElement = s[s.length - 1]?.sequence + 100 || 0;
      const updatedArray = [
        ...this.combinedList$.getValue(),
        {
          row_type: 'header',
          properties: {},
          sequence: seqeunceFromLastElement,
        },
      ];
      this.combinedList$.next(updatedArray);
      this.scrollToBottom();
    });
  }

  addProductInput() {
    this.sortedList$.pipe(first()).subscribe((s) => {
      const seqeunceFromLastElement = s[s.length - 1]?.sequence + 100 || 0;
      const updatedArray = [
        ...this.combinedList$.getValue(),
        {
          row_type: 'addProduct',
          sequence: seqeunceFromLastElement,
        },
      ];
      this.combinedList$.next(updatedArray);
      this.scrollToBottom();
    });
  }

  scrollToBottom() {
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTo({
        top: this.scrollContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  }

  updateLine(line: Line) {
    this.updateLineDebounce.next({ line });
  }

  setValueOnLine(line: Line, event, property) {
    const type = this.getInputTypeBasedOnDbType(property.dbtype);
    switch (type) {
      case 'checkbox':
        line.properties[property.name] = event.currentTarget.checked;
        break;
      default:
        line.properties[property.name] = event.currentTarget.value;
        break;
    }
    this.updateLine(line);
  }

  copyListToBasket() {
    this.listService.copyListToBasket(this.data.listId)
      .subscribe(() => {
        this.notificationService.show('Added to basket', 'success');
      });
  }

  printList() {
    this.listService.printList(this.data.listId);
  }

  printAsInfoList() {
    this.listService.printListAsInfoCards(this.data.listId);
  }

  deleteList() {
    const list = this.list$.getValue();
    this.listService.deleteList(list).subscribe(() => {
      this.close();
    });
  }

  addProductToList(product: IGetProduct, line: Line) {
    this.list$
      .pipe(
        switchMap((list) => {
          return this.listService.addProductToList(
            list.list_id,
            product.seourlid
          );
        })
      )
      .subscribe((newLines: Line[]) => {
        if (!newLines || newLines.length == 0) {
          return;
        }
        this.searchProductInput.next({ value: '', trigger: undefined });
        const existingArray = this.combinedList$.getValue();
        const indexOfProductInputLine = existingArray.indexOf(line);
        existingArray[indexOfProductInputLine] = newLines[0];
        this.combinedList$.next(existingArray);
      });
  }

  close() {
    this.dialogref.close();
  }

  removeLine(lineId: number, line: Line) {
    const newArray = this.combinedList$.getValue();
    this.combinedList$.next(newArray.filter((s) => s != line));
    if (lineId) {
      this.listService.removeLines([lineId]).subscribe();
    }
  }

  doSearch(event, trigger: MatAutocompleteTrigger) {
    this.searchProductInput.next({
      value: event.currentTarget.value,
      trigger: trigger,
    });
  }

  getPropertiesAsArray() {
    return this.list$.getValue().line_collection;
  }

  getInputTypeBasedOnDbType(type: string) {
    if (type.toLowerCase().includes('decimal')) {
      return 'number';
    }
    if (type.toLowerCase().includes('smallint')) {
      return 'checkbox';
    }
    return 'text';
  }

  openDialogMail(list) {
    const dialogRef = this.matDialog.open(DialogComponent, {
      width: '450px',
      data: {
        title: 'Indtast e-mail adresse',
        placeholder: 'E-mail adresse',
        input: '',
      },
    });
    dialogRef.beforeClosed().subscribe((email) => {
      if (email !== undefined) {
        const payload = {
          list_id: list.list_id,
          email_address: email,
        };
        this.listService
          .sendList(payload)
          .pipe(first())
          .subscribe(
            () => {
              this.notificationService.show('Liste delt', 'success');
            },
            (error) => {
              this.notificationService.show(
                'Fejl ved liste deling. Prøv igen',
                'error'
              );
              console.error(error);
            }
          );
      }
    });
  }
}

export interface EditListDialog {
  listId: string;
}
