import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {Table} from 'primeng/table';

@Component({
  selector: 'ngx-sitemule-table-p',
  templateUrl: './table-p.component.html',
  styleUrls: ['./table-p.component.scss'],
})
export class TablePComponent {
  @Input() tableInitData: ITablePdata;
  @Input() tableRowData: ITablePRowData[];
  @Output() rowClickOut = new EventEmitter();
  @Output() lazyLoadEvt = new EventEmitter();
  @ViewChild(Table) pTable: Table;
  id = Math.random();

  selectedRow: any;

  constructor() {
  }

  /**
   * Eventemitting the rowData out.
   * @param rowData
   */
  rowClick(rowData) {
    this.rowClickOut.emit(rowData);
  }

  getSwitchCase(row, col) {
    if (row[col.field]?.type) {
      return row[col.field].type
    } else if (col.type) {
      return col.type;
    } else {
      return ''
    }
  }

  lazyLoadEmit(evt) {
    this.lazyLoadEvt.emit(evt);
  }

  preventClick(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  linkClick(link, evt: Event) {
    this.preventClick(evt);
    window.open(link);
  }
}

export interface ITablePdata {
  options?: {
    rows?: number; // specifies number of rows to be shown.
    virtualScrolling?: boolean; // enables virtualscrolling.
    virtualScrollingHeight?: number; // setting height virtualheight on rows
    sort?: boolean; // Enables sort.
    filter?: boolean; // Enables filter.
    lazy?: boolean; // Enables lazy loading, NOT IMPLEMENTED.
    globalFilter?: boolean; // Enables globalFilter
    globalFilterFields?: []; // List of fields to be global filtered by.
    scrollable?: boolean; // if table should be scrollable
    scrollHeight?: string; // Setting scroll heigh of view. If present, scrollable is set to true.
    scrollHeightBasedOnElements?: number; // Setting scroll height based on elements.
    responsiveLayout?: string; // If layout is responsive.
    lazyLoadOnInit?: boolean; // States it self. Run lazyload function on init table.
    scrollDirection?: 'vertical' | 'horizontal' | 'both'; // Setting scroll direction of view.
  };
  caption?: string;
  columns?: Columns[];
  rowData?: any;
  summary?: string;
  footer?: boolean;
}

export interface ITablePRowData {
  type?: CellType;
  options?: string[];
  selected?: boolean;
  text?: string;
}

interface Columns {
  header: string;
  field: string;
}

enum CellType {
  DROPDOWN = 'dropdown',
  LINK = 'link',
}
