<div class="user-orders-container" *ngIf="{activeOrderId: activeOrderId$ | async} as activeOrderIdObj">
  <form class="filter-bar" [formGroup]="searchForm">
    <div class="ui-input-icon-group">
      <i class="icon-Soeg"></i>
      <input #searchEl type="text" #input formControlName="search"
        placeholder=" {{'web.order.search.placeholder' | translate}}">
    </div>
  </form>
  <div class="orders">
    <ngx-loading
      [ngStyle]="{
        position: 'relative',
        display: 'block',
        minHeight: '80px'
      }"
      [config]="{
        primaryColour: 'var(--primary-color)',
        secondaryColour: 'var(--primary-color)',
        tertiaryColour: 'var(--primary-color)',
        backdropBackgroundColour: 'transparent',
        backdropBorderRadius: '3px'
      }"
      [show]="true"
      *ngIf="loadingOrders$ | async"></ngx-loading>
    <ng-container *ngIf="orderGroups$ | async as orderGroups">
      <ng-container *ngIf="orderGroups.length; else noItems">
        <div class="order-group" *ngFor="let group of orderGroups">
          <div class="order-category-title">
            {{group.description | translate }}</div>
          <div class="order-list-header">
            <div class="head">
              {{'web.order.order' | translate }}
            </div>
            <div class="head">
              {{'web.order.date' | translate }}
            </div>
            <div class="head">
              {{'web.order.customer' | translate }}
            </div>
            <div class="head">
              {{'web.order.reference' | translate }}
            </div>
            <div class="head">
              {{'web.order.total' | translate }}
            </div>
          </div>
          <div #orderElements class="order {{order.id}}" *ngFor="let order of group.items"
            (click)="setOrderActive(order.id); " [class.active]="order.id == activeOrderIdObj.activeOrderId">
            <div class="closed">
              <span>
                {{order.id}}
              </span>
              <span>
                {{order.orderDate | date: 'dd-MM-yyyy'}}
              </span>
              <span>
                {{order.customerName}}
              </span>
              <span>
                {{order.customerReference}}
              </span>
              <span style="text-align: right;">
                {{order.totalAmount | sitemuleCurrency: 'financial'}}
              </span>
            </div>
            <div class="opened" *ngIf="order.id == activeOrderIdObj.activeOrderId">
              <div class="action-bar">
                <i class="material-icons-outlined" >print</i>
                <i  (click)="copyOrderToBasket(order); $event.stopPropagation()" class="material-icons-outlined">shopping_basket</i>
                <div></div>
                <i class="icon-arrowup" style="font-size: 16px;" (click)="setOrderActive(undefined); $event.stopPropagation()"> </i>
              </div>
              <div class="order-content">

                <div class="order-number">
              {{'web.order.order' | translate }} : {{order.id}}
                </div>
                <div class="buyer-info">
                  <div class="info-box ">
                    <b>{{'web.order.billingaddress' | translate }}</b>
                    <p>{{order.billing.name}}</p>
                    <p>{{order.customerName}}</p>
                    <p>{{order.billing.address1}}</p>
                    <p>{{order.billing.zipcode}} {{order.billing.city}}</p>
                  </div>
                  <div class="info-box ">
                    <b>{{'web.order.deliveryaddress' | translate }}:</b>
                    <p>{{order.delivery.name}}</p>
                    <p>{{order.delivery.address1}}</p>
                    <p>{{order.delivery.zipcode}} {{order.delivery.city}}</p>
                  </div>
                  <div class="info-box "><b>{{'web.order' | translate }}</b>
                    <p>{{'web.order.date' | translate }}: {{order.orderDate | date: 'dd-MM-yyyy'}}</p>
                    <p>{{'web.order.deliverydate' | translate }}: {{order.deliveryDate | date: 'dd-MM-yyyy'}}</p>
                    <p>{{'web.order.yourordernumber' | translate }}: {{order.id}}</p>
                    <p>{{'web.order.yourreference' | translate }}: {{order.reference}}</p>
                    <p>{{'web.order.comment' | translate }}: {{order.deliveryRemark}}</p>
                  </div>
                </div>
                <ngx-loading
                  [ngStyle]="{
                    position: 'relative',
                    display: 'block',
                    minHeight: '80px'
                  }"
                  [config]="{
                    primaryColour: 'var(--primary-color)',
                    secondaryColour: 'var(--primary-color)',
                    tertiaryColour: 'var(--primary-color)',
                    backdropBackgroundColour: 'transparent',
                    backdropBorderRadius: '3px'
                  }"
                  [show]="true"
                  *ngIf="loadingOrderLines$ | async"></ngx-loading>
                <user-pricelist *ngIf="activeOrderLines$ | async as activeOrderLines" [basket]="mergeOrder(order, activeOrderLines)"></user-pricelist>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
      <ng-template #noItems>
        <h1>{{'web.order.noorders' | translate }}</h1>
      </ng-template>
    </ng-container>

  </div>
</div>
