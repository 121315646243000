<div id="print-section">
  <div class="edit-list-container" #scrollContainer *ngIf="list$ |async as list">
    <div class="header">
      <div class="back" (click)="close()">
        <span class="material-icons ">
          arrow_back
        </span>
      </div>
      <div class="title">
        {{list.description}}
      </div>
      <div class="buttons">
        <button style="border: none; background: none;" tabindex="-1" class="material-icons"
          (click)="copyListToBasket()">
          add_shopping_cart
        </button>
        <button style="border: none; background: none;" tabindex="-1" class="material-icons" (click)="printList()">
          print
        </button>
        <span class="material-icons" (click)="printAsInfoList()">
          view_module
        </span>
        <span class="material-icons" (click)="deleteList()">
          delete
        </span>
        <button class="ui-button success" (click)="openDialogMail(list)" style="margin-left: 20px;">
          {{'web.share' | translate}}
        </button>
      </div>
    </div>
    <div class="logo-line">
      <img src="/assets/logo.png" class="logo" />
    </div>
    <div class="list-info">
      <ng-container *ngIf="currentSession$ | async as currentSession">
        <div class="info-box ">
          <b>{{'web.order.billingaddress' | translate }}</b>
          <p>{{currentSession.customer.name}}</p>
          <ng-container *ngIf="primaryAddress$ | async as address">
            <p>{{address.name}} <br>
              {{address.address_1}} <br>
              {{address.country_code}} - {{address.zipcode}} {{address.city}}
            </p>
            <p>{{address.email}}</p>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="list-items-container">
      <div class="list-items-extra">
        <input type="text" [(ngModel)]="list.description" (blur)="updateList()"
          placeholder="{{'web.user.list.entertitle' | translate}}" class="ui-input title">
        <span class="date">
          <input class="ui-input" (dateChange)="dateSelected($event.value)" value="{{list.properties.date}}" readonly
            style="pointer-events: none; text-align: right;" matInput [matDatepicker]="picker" placeholder="Vælg dato">
          <mat-datepicker #picker></mat-datepicker>
          <span (click)="picker.open()" class="material-icons">
            calendar_today </span>
        </span>

        <textarea cdkTextareaAutosize style="box-sizing:content-box;" type="text" [(ngModel)]="list.properties.comment"
          (blur)="updateList()" placeholder="{{'web.user.list.entercomment' | translate}}"
          class="ui-input commen comment">
                </textarea>
      </div>
      <div class="list-items-header">
        <div class="head" style="flex: 1;"></div>
        <div class="head">{{'web.webshop.product.indicative_price' | translate}}</div>
        <div class="head" *ngFor="let head of list.line_collection">
          {{head.description}}
        </div>
      </div>

      <div class="drag-list-container" cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let item of sortedList$ | async; index as i">
          <ng-container [ngSwitch]="item.row_type">
            <ng-container *ngSwitchCase="'addProduct'">
              <div class="edit-list-item add-product" cdkDrag cdkDragLockAxis="y">
                <div class="left" cdkDragHandle>
                  <span class="material-icons">
                    drag_indicator
                  </span>
                </div>
                <div class="middle">
                  <input type="text" #trigger="matAutocompleteTrigger" (input)="doSearch($event, trigger)"
                    placeholder="{{'web.order.searchandadditemstobasket' | translate }}" class="ui-input"
                    [matAutocomplete]="auto">
                  <mat-autocomplete (optionSelected)='addProductToList($event.option.value, item)'
                    #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let product of filteredProducts$ | async" [value]="product">
                      {{product.label}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="right">
                  <span class="material-icons" (click)="removeLine(undefined, item)">
                    close
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'header'">
              <div class="edit-list-item title" cdkDrag cdkDragLockAxis="y">
                <div class="left" cdkDragHandle>
                  <span class="material-icons">
                    drag_indicator
                  </span>
                </div>
                <div class="middle">
                  <input class="text" placeholder="Indsæt tekst her" [(ngModel)]="item.properties.res_text" #test
                    (input)="updateLine(item)" />
                  <textarea cdkTextareaAutosize class="description ui-input" placeholder="Tilføj beskrivelse"
                    [(ngModel)]="item.properties.res_description" (input)="updateLine(item)"> </textarea>

                </div>
                <div class="right">
                  <span class="material-icons" (click)="removeLine(item.list_line_id, item)">
                    close
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'product'">
              <div class="edit-list-item product" cdkDrag cdkDragLockAxis="y">
                <div class="left" cdkDragHandle>
                  <span class="material-icons">
                    drag_indicator
                  </span>
                </div>
                <div class="middle">
                  <img src="/.0/cms/scaleImage.aspx?path={{item.product.imageUrl}}&width=218&height=175" alt="">
                  <div class="text">
                    <a style="display: none; text-decoration: none; color: #353535;"
                      href="http://folkelarsen.dk">01.200</a>
                    <b>{{item.title}}</b>
                    <div class="description" [innerHTML]="(item.product.productnumber + ' - ' + (item.product.tekniskBeskrivelse || item.product.body)) | marked">
                    </div>
                    <textarea cdkTextareaAutosize class="description ui-input" placeholder="Tilføj beskrivelse"
                      [(ngModel)]="item.properties.res_description" (input)="updateLine(item)"> </textarea>
                  </div>
                  <div class="values">
                    <div class="value">
                      {{item.product.price | sitemuleCurrency : 'pretty' }}
                    </div>
                    <div class="value" *ngFor="let property of getPropertiesAsArray()">
                      <input class="ui-input" [checked]="item.properties[property.name]"
                        [type]="getInputTypeBasedOnDbType(property.dbtype)" step="0.01"
                        (input)="setValueOnLine(item, $event, property)" [(ngModel)]="item.properties[property.name]"
                        [placeholder]="property.description" />
                    </div>
                  </div>
                </div>
                <div class="right">
                  <span class="material-icons" (click)="removeLine(item.list_line_id, item)">
                    close
                  </span>
                </div>
              </div>
            </ng-container>
            <div *ngSwitchDefault>type not found: {{item.row_type}}</div>
          </ng-container>
        </ng-container>

      </div>
    </div>
    <div class="buttons-container">
      <button class="ui-button" (click)="addProductInput()">Tilføj Produkt</button>
      <button class="ui-button" (click)="addTextInput()">Tilføj Overskrift</button>
    </div>
  </div>
</div>
