import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {

  defaultCurrency = 'DKK';
  defaultLocale = 'da';

  constructor(
    private translate: TranslateService,
    private currency: CurrencyPipe
  ) {}

  transform(value, showType?: 'financial' | 'pretty'): string {


    if (showType === 'financial') {
      return this.currency.transform(value, this.defaultCurrency, '', '1.2-2', this.defaultLocale);
    }
    if (showType === 'pretty') {
      // Somethign we get `value: '0'`
      if (!value || (value.trim && value.trim() === '0')) {
        return this.translate.instant('web.webshop.askforprice');
      }

      // Show two decimal if value is a float
      if (value % 1 != 0) {
        return this.currency.transform(value, this.defaultCurrency, '', '1.2-2', this.defaultLocale);
      }

      return this.currency.transform(value, this.defaultCurrency, '', '1.0-0', this.defaultLocale) + ',-';
    }

    return value;
  }
}
