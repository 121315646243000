import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ScaleImageDirectiveModule } from '@sitemule/ng-components/directives/scaleimage';
import { MarkdownModule } from '@sitemule/ng-components/pipes/marked';
import { NgxLoadingModule } from 'ngx-loading';
import { SitemuleCoreModule } from '../../core/sitemule-core.module';
import { SparePartsModule } from '../../page/components/spare-parts/spare-parts.module';
import { SitemuleUiModule } from '../../ui/sitemule-ui.module';
import { CatalogEntryComponent } from './catalog-entry.component';
import { CatalogService } from './catalog.service';
import { CatalogItemComponent } from './components/catalog-item/catalog-item.component';
import { CatalogMiniComponent } from './components/catalog-mini/catalog-mini.component';
import { CatalogComponent } from './components/catalog/catalog.component';
import { CatalogFilterComponent } from './components/filter/filter.component';
import { MenuComponent } from './components/menu/menu.component';

@NgModule({
  declarations: [
    CatalogComponent,
    CatalogItemComponent,
    CatalogEntryComponent,
    CatalogFilterComponent,
    MenuComponent,
    CatalogMiniComponent,
  ],
  imports: [
    CommonModule,
    SitemuleUiModule,
    SitemuleCoreModule,
    RouterModule,
    TranslateModule.forChild(),
    NgxLoadingModule,
    SparePartsModule,
    ScaleImageDirectiveModule,
    MarkdownModule,
  ],
  exports: [
    CatalogComponent,
    CatalogItemComponent,
    CatalogEntryComponent,
    CatalogMiniComponent,
  ],
  providers: [CatalogService],
})
export class CatalogModule {}
