import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  /* Sets the defaults for MatSnackBar */
  private snackbarConfig: MatSnackBarConfig = {
    duration: 8000,
  };
  constructor(public snackBar: MatSnackBar) { }

  show(message: string, theme: 'error' | 'success' | 'primary'): void {
    this.snackbarConfig.panelClass = 'notification-' + theme;
    this.snackBar.open(message, 'LUK', this.snackbarConfig);
  }
}
