import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

interface IPDFData {
  templatePath: string;
  pathOnly?: boolean;
  data: Object;
}

interface IPDFEntityData {
  templatePath: string;
  id: string;
  entity: 'list' | 'infocard' | 'product' | 'order';
}

@Injectable({
  providedIn: 'root',
})
export class PDFService {
  constructor(private http: HttpClient) {}

  private getPDFWithData<T>(
    data: IPDFData,
    accept: string,
    responseType?: 'json'
  ) {
    return this.http.post<T>('/cms/cms/createPdfByTemplate', data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: accept,
      },
      responseType,
    });
  }

  getPDFAsLinkWithData(data: Omit<IPDFData, 'pathOnly'>) {
    return this.getPDFWithData<{ path: string }>(
      {
        ...data,
        pathOnly: true,
      },
      'application/json',
      'json'
    );
  }

  getPDFAsBlobWithData(data: Omit<IPDFData, 'pathOnly'>) {
    return this.getPDFWithData<string>(
      data,
      'application/pdf',
      'blob' as 'json'
    ).pipe(
      map((data) => {
        return new Blob([data], {
          type: 'application/pdf',
        });
      })
    );
  }

  openPDFWithData(data: Omit<IPDFData, 'pathOnly'>): Promise<string> {
    const win = this.openWindow('');
    return new Promise((resolve, reject) => {
      return this.getPDFAsLinkWithData(data).subscribe({
        next: (res) => {
          win.location = res.path;
          resolve(res.path);
        },
        error: () => {
          win.close();
          reject();
        },
      });
    });
  }

  openPDFWithEntity(data: IPDFEntityData) {
    return this.openWindow(
      `/cms/cmn/createpdf?payload=${encodeURI(JSON.stringify(data))}`
    );
  }

  openWindow(link: string) {
    return window.open(link);
  }
}
