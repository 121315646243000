<!--<pdf-viewer style="height: 50%; width: 50%" [src]="pdf"-->
<!--            [original-size]="true"-->
<!--            [show-borders]="true"-->
<!--&gt;</pdf-viewer>-->

<div class="pdf-viewer-container">
  <img style="margin: auto;width: 90%;" [src]="url" *ngIf="viewer == 'url'">
  <div *ngIf="viewer == 'office'" style="height: 30px">
    <div style="float: right; height: 100%; align-items: center; display:flex; cursor:pointer"
         (click)="download()">
      Download
      <i class="material-icons "
         style="display: flex;
            align-items: center; font-size: 20px"
         >
        file_download
      </i>
    </div>
    <!--  <i class="icon-arrowright" ></i>-->
  </div>
  <ngx-doc-viewer [url]="url" [viewer]='viewer' *ngIf="viewer != 'url'"
                  style="height: 100%; max-width: 100%; flex: 1;"></ngx-doc-viewer>


  <!--  <ng-template #customToolbar>-->
  <!--    <div id="toolbarViewer" class="pdfCustomToolBar">-->
  <!--      <div id="toolbarViewerLeft">-->
  <!--      </div>-->
  <!--      <div id="toolbarViewerRight" class="pdfToolbarViewerRight">-->
  <!--        <pdf-find-button [showFindButton]="false" [textLayer]="true"></pdf-find-button>-->
  <!--        <div class="material-icons" id="print">-->
  <!--          print-->
  <!--        </div>-->
  <!--        <div class="material-icons" id="download">-->
  <!--          download-->
  <!--        </div>-->
  <!--        &lt;!&ndash;      <pdf-download></pdf-download>&ndash;&gt;-->
  <!--        &lt;!&ndash;      <button mat-icon-button id="download">&ndash;&gt;-->
  <!--        &lt;!&ndash;        <mat-icon>download</mat-icon>&ndash;&gt;-->
  <!--        &lt;!&ndash;      </button>&ndash;&gt;-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </ng-template>-->

</div>
