import { HttpClient } from '@angular/common/http';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
} from '@ngx-translate/core';
import { Observable } from 'rxjs';

class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Object> {
    return this.http.get<Object>(`/mnu/txt/listTextTranslation/cms/${lang}`);
  }
}

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      // @ts-ignore
      if (params.interpolateParams['default'] !== undefined) {
        return params.interpolateParams['default'];
      } else {
        return params.key;
      }
    }
    return '[MISSING] = ' + params.key;
  }
}

export const DefaultTranslateConfig = {
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: MissingTranslationHelper,
  },
  loader: {
    provide: TranslateLoader,
    useClass: CustomTranslateHttpLoader,
    deps: [HttpClient],
  },
};
