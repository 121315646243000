import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from '@sitemule/ng-components/pipes/marked';
import { NgxPrintModule } from 'ngx-print';
import { SitemuleCoreModule } from '../../core/sitemule-core.module';
import { ListItemComponent } from './components/list-item/list-item.component';
import { AddToListComponent } from './dialog/add-to-list/add-to-list.component';
import { CreateListComponent } from './dialog/create-list/create-list.component';
import { EditListComponent } from './dialog/edit-list/edit-list.component';
import { ListOverviewPageComponent } from './pages/list-overview/list-overview-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: ':listType',
        component: ListOverviewPageComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    ListOverviewPageComponent,
    CreateListComponent,
    EditListComponent,
    AddToListComponent,
    ListItemComponent,
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    TextFieldModule,
    SitemuleCoreModule,
    DragDropModule,
    RouterModule,
    NgxPrintModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    MarkdownModule,
  ],
  exports: [],
})
export class ListModule {}
