import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DTOgetPage as DTOGetPageBySeo, PageItem } from './page.interface';

const shuffle = (array: any[]) : any[] => {
  let currentIndex = array.length,  randomIndex;
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const modifyContent = (item: any) => {
  if (item.contentType === 'stack') {
    return {
      ...item,
      items: item.items?.map(i => modifyContent(i))
    }
  }
  // suffle teasers if needed
  if ((item.contentType === 'teaserstack2' || item.contentType === 'newteaserstack2') && item.properties.mixTeasers) {
    return {
      ...item,
      items: shuffle(item.items)
    }
  }

  return item;
};
@Injectable({
  providedIn: 'root',
})
export class SitemulePageService {
  constructor(private http: HttpClient) {}

  private _getContent(idOrSeo: string, isId = false) {
    if (isId) {
      return this.getPageById(idOrSeo);
    }
    return this.getContentBySeoUrl(idOrSeo);
  }

  getContent(idOrSeo: string, isId = false) {
    return this._getContent(idOrSeo, isId).pipe(map(res => {
      return {
        ...res,
        content: modifyContent(res.content)
      };
    }))
  }

  getPageById(id: string): Observable<DTOGetPageBySeo> {
    return this.http.get<PageItem>(`/cms/pag/getPage/${id}`).pipe(map((pageItem => {
      return {
        type: 'page', // ID is only supported by pages
        seourl: pageItem.seourl,
        content: pageItem
      };
    })));
  }

  getContentBySeoUrl(seoUrl: string): Observable<DTOGetPageBySeo> {
    return this.http.get<DTOGetPageBySeo>(`/cms/prd/getContentBySeoUrl/${seoUrl}`);
  }
}
