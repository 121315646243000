<div *ngIf="data" class="contact-cards-container 
{{layout || data.layout?.type}}">
    <div class="card" *ngFor="let contact of data.content?.contacts">
        <div class="logo" [style.background-image]="'url(' + (contact.logo || logo) + ')'"></div>
        <div class="image" [style.background-image]="'url(' + contact.image + ')'"></div>
        <div class="text">
            <div class="name">{{contact.name}}</div>
            <div class="title">{{contact.title}}</div>
            <a [href]="'tel:' + contact.phone" class="phone">{{contact.phone}}</a>
            <a [href]="'mailto:' +contact.email" class="email">{{contact.email}}</a>
        </div>
    </div>
</div>