<div class="container">

  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="ui-input-form">
      <label> {{'web.login.email' | translate}}</label>
      <input type="text" formControlName="username" >
    </div>
    <div class="ui-input-form">
      <label> {{'web.login.password_placeholder' | translate}}</label>
      <a (click)="forgotPassword()" *ngIf="!resetPasswordMsg" style="cursor: pointer;" class="hint forgotten-password">
        {{'web.login.forgottenpassword'|lowercase | translate}}
      </a>
      <input type="password" formControlName="password" name="password"  [type]="togglePassword ? 'text' : 'password'">
      <span (click)="togglePassword = !togglePassword" class="material-icons-outlined toggle-password">{{
        !togglePassword ?'visibility_off' : 'visibility'}}</span>
    </div>
    <ng-container *ngIf="errorSubmit">
      <div style="display:flex; flex-direction: column;">
        <div style="text-align: center; color: red;margin: 10px 20px 20px 20px;">
          <span>{{errorSubmit}}</span>
        </div>
      </div>
    </ng-container>
    <span>{{resetPasswordMsg}}</span>
    <div style="display:flex; flex-direction: column;">
      <div class="buttons">
        <button type="submit" class="login-submitButton ui-button success">{{'web.login.submitbutton'|lowercase |
          translate}}
        </button>
      </div>
    </div>
  </form>

  <div class="noAccount">
    <span (click)="openComponent('registration')">{{'web.login.createaccount' | translate}}
    </span>
  </div>

</div>
