import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnChanges {
  @Input() selectedTabIndex = 0;

  constructor(private hostComponent: ElementRef) {}

  ngOnChanges(_changes: SimpleChanges) {
    const children = this.hostComponent.nativeElement.children;
    for (let index = 0; index < children.length; index++) {
      const child = children[index];
      child.classList.remove('active');
      if (index == this.selectedTabIndex) {
        child.classList.add('active');
      }
    }
  }
}
