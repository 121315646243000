import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SEOService } from '../../core/services/seo.service';
import { CatalogContent } from '../../core/services/webshop.service';
import { ICatalogFilter } from './components/filter/filter.interface';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  // TODO: Look thro this service later on, for better code.
  catalogList: CatalogContent = undefined;
  originalCatalogList: CatalogContent = undefined;
  catalogMenuList = [];
  filterMenuList = [];

  // FIXME: Tommorow! For real!!
  listOfSelectedFilterGroups;
  filterInit = false;
  originalfilterMenuList = [];

  private catalogSource = new BehaviorSubject(this.catalogList);
  catalogs = this.catalogSource.asObservable();

  private catalogMenuSource = new BehaviorSubject(this.catalogMenuList);
  catalogMenus = this.catalogMenuSource.asObservable();

  // private filterMenuSource = new BehaviorSubject(this.filterMenuList);
  private filterMenuSource = new BehaviorSubject(undefined);
  filterMenus = this.filterMenuSource.asObservable();

  constructor(
    private http: HttpClient,
    private seoService: SEOService
  ) {}


  setCatalogList(result: CatalogContent) {
    this.catalogList = result;
    this.seoService.setTitleAndMeta({
      title: result.title,
      metaDescription: result.metadescription ?? result.title ?? '', // Catalog doesn't have meta details
      metaKeywords: result.metakeywords
    });
    this.originalCatalogList = result;
    this.catalogSource.next(this.catalogList);
  }

  setCatalogFilteredList(result) {

    this.catalogList = result;
    this.catalogSource.next(this.catalogList);
  }

  setOriginalCatalogAndFilterList() {
    this.filterMenuSource.next(JSON.parse(JSON.stringify(this.originalfilterMenuList)));
    this.catalogList = JSON.parse(JSON.stringify(this.originalCatalogList));
    this.catalogSource.next(this.catalogList);

  }

  getCatalogFilterBySeo(seoUrl): Observable<ICatalogFilter[]> {
    return this.http.get<ICatalogFilter[]>('/cms/prd/listProductCatalogFiltersBySeoUrl/' + encodeURIComponent(seoUrl)).pipe(
      map(res => {
        // Response can be an object with `success:false`, so checking the `length` to make sure it is an array
        if (res && res.length) {
          return res;
        }
        return [];
      })
    )
  }


  setCatalogListMenu(result: CatalogContent) {

    this.catalogList = result;
    this.catalogSource.next(this.catalogList);
  }

  setCatalogMenuList(list) {
    // list.forEach(item => {
    //   this.mapFuncMenu(item);
    // });
    this.catalogMenuList = list;
    this.catalogMenuSource.next(this.catalogMenuList);
  }

  initCatalogMenuList() {
    if (this.catalogMenuList.length === 0) {
      this.http.get('/cms/prd/getContentBySeoUrl/').pipe(first()).subscribe((res: any) => {
        this.setCatalogMenuList(res.content.items);
      });
    }
  }

  initFilterMenu(seoUrl, filterList?) {
    if (!this.filterInit) {
      this.filterInit = true;
      this.getCatalogFilterBySeo(seoUrl).pipe(first()).subscribe(res => {
        this.filterMenuList = JSON.parse(JSON.stringify(res)) || [];
        this.originalfilterMenuList = JSON.parse(JSON.stringify(res)) || [];

        if (filterList) {
          filterList = JSON.parse(filterList);
          this.filterMenuList.forEach(parentFilter => {
            const found = filterList[parentFilter.label];
            if (filterList.master === parentFilter.label) {
              parentFilter.isMasterFilter = true;
            }
            if (found) {
              parentFilter.values.forEach(val => {
                if (found.includes(val.value)) {
                  val.selected = true;
                }
              });
            }
          });
        }
        const data = { selectedFilterGroups: [] };
        this.filterMenuList.forEach(x => {
          if (x.isMasterFilter) {
            data.selectedFilterGroups = [...data.selectedFilterGroups, x];
          }
          x.values.forEach(y => {
            if (y.selected) {
              if (!data.selectedFilterGroups.find(a => a === x)) {
                data.selectedFilterGroups = [...data.selectedFilterGroups, x];
              }
            }
          });
        });
        this.listOfSelectedFilterGroups = data;

        this.filterMenuSource.next(this.filterMenuList);
        return this.listOfSelectedFilterGroups;
      });
    }
    return this.listOfSelectedFilterGroups;
  }

  // setFilterMenu(seoUrl, filterList) {
  //   this.initFilterMenu(seoUrl, filterList);
  // }

  resetFilter() {
    this.filterInit = false;
    this.filterMenuSource.next([]);
  }

  addChildToMenu(menu) {
    // this.catalogMenuSource.next(this.catalogMenuList);
    if (menu.leaf !== 1) {
      const foundMenu = this.FindMenuFromNestedList(this.catalogMenuList, menu);
      if (foundMenu) {
        if (foundMenu.items === undefined) {
          foundMenu.items = [];
        }
        if (foundMenu.items?.length === 0) {
          this.http.get('/cms/prd/getContentBySeoUrl/' + encodeURIComponent(menu.seourl)).pipe(first())
            .subscribe((res: any) => {
              // res.content.items.forEach(item => {
              //   this.mapFuncMenu(item);
              // });
              foundMenu.items = res.content.items;
            });
          this.catalogMenuSource.next(this.catalogMenuList);
        }
      }
    }
    // else {
    //   const foundMenu = this.FindMenuFromNestedList(this.catalogMenuList, menu);
    // }
  }

  getOrgCatalog() : CatalogContent {
    return JSON.parse(JSON.stringify(this.originalCatalogList));
  }

  // Todo: return new object rather than altering parent.
  private FindMenuFromNestedList(list, menu) {
    let output;
    list.forEach(item => {
      if (item.seourl === menu.seourl) {
        output = item;
        return;
      }
      if (item.items?.length) {
        const temp = this.FindMenuFromNestedList(item.items, menu);
        if (temp) {
          output = temp;
          return;
        }
      }
    });
    return output;
  }
}
