import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageRedirectorGuard implements CanActivate {
  constructor(private router: Router, private translateService:TranslateService, private appService:AppService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.translateService.defaultLang || (this.translateService.getDefaultLang() != state.url.split('/')[1])) {
      this.appService.setLanguage(state.url);
    }
      this.router.navigateByUrl( '/' + this.translateService.defaultLang + state.url);
    return true;
  }

}

