import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'formatDate2',
  pure: true,
})
export class DateFormat2Pipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
  ) {
  }

  transform(value: number | string | undefined | null | Date): string {

    if (!value) {
      return '';
    }

    if (typeof value === 'number') {
      value = value.toString();
    }

    // Temporary fix for backend sending invalid date `dd.mm.yy`, Also the data is scrambled. For ex `10.86.86`
    if (typeof value === 'string' && value.indexOf('.') > -1) {
      console.warn(`Using invalid date format ${value}`);
      return value;
    }

    // Temporary fix for backend sending invalid date `dd-mm-yyyy`
    if (typeof value === 'string' && isNaN(Date.parse(value))) {
      console.warn(`Using invalid date format ${value}`);
      if (value.includes('-')) {
        const splitValue = value.split('-');
        if (splitValue.length === 3) {
          value = new Date(`${splitValue[2]}-${splitValue[1]}-${splitValue[0]}`);
        }
      } else if (value.length == 8) {
        if (value.startsWith("202") || value.startsWith("201") || value.startsWith("203")) {
          value = new Date(value.substring(0, 4) + "-" + value.substring(4, 6) + "-" + value.substring(6, 8))
        }
      }
    }

    const locale = this.translateService.getBrowserCultureLang();
    return new Date(value).toLocaleDateString(locale);
  }
}
